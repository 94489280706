import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Newuser from "./components/Panel_Users/New_User/Newuser";
import Allusers from "./components/Panel_Users/All_Users/Allusers";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "./Utils/Common/Common";
import ProtectedRoutes from "./Utils/ProtectedRoutes/ProtectedRoutes";
import Edituser from "./components/Panel_Users/All_Users/Edit_User/Edituser";
import ExecutiveReport from "./components/Panel_Users/Exexutive_Report/ExecutiveReport";
import LivestreamVideoTable from "./components/Content/Livestream_Video_Table/LivestreamVideoTable";
import UploadedVideoTable from "./components/Content/Uploaded_Video_Table/UploadedVideoTable";
import ShortsVideoTable from "./components/Content/Shorts_Video_Table/ShortsVideoTable";
import UserVerification from "./components/VerificationContent/User_Verification_Status/UserVerification";
import AddverificationRecord from "./components/VerificationContent/Add_Verification_Record/AddverificationRecord";
import VideocommentReports from "./components/VerificationContent/Video_Comment_Reports/VideocommentReports";
import AddNewFaq from "./components/FAQs/Add_FAQ/AddNewFaq";
import FaqDescription from "./components/FAQs/Add_FAQ/FaqDescription";
import AllFaq from "./components/FAQs/All_FAQ/AllFaq";
import AddNewBlog from "./components/Blogs/AddNewBlogs/AddNewBlog";
import BlogDescription from "./components/Blogs/AddNewBlogs/BlogDescription";
import AllBlogs from "./components/Blogs/AllBlogs/AllBlogs";
import Editblog from "./components/Blogs/Edit_Blogs/Editblog";
import EditblogDescription from "./components/Blogs/Edit_Blogs/EditblogDescription";
import EditFaq from "./components/FAQs/Edit_FAQ/EditFaq";
import EditFaqDescription from "./components/FAQs/Edit_FAQ/EditFaqDescription";
import AddGame from "./components/Games/Add_New_Game/AddGame";
import GameCategories from "./components/Games/Game_Categories/GameCategories";
import EditGames from "./components/Games/Edit_Games/EditGames";
import AddManageGamers from "./components/Games/Add_Manage_Gamers/AddManageGamers";
import UploadedVideosReport from "./components/Content_Reporting/Uploaded_Video_Report/UploadedVideosReport";
import LivestreamVideoReport from "./components/Content_Reporting/Livestream_Video_Report/LivestreamVideoReport";
import DeactivatedVideoReport from "./components/Content_Reporting/Deactivated_Video_Report/DeactivatedVideoReport";
import DeactivatedLivestreamReport from "./components/Content_Reporting/Deactivated_Livestream_Report/DeactivatedLivestreamReport";
import GameReport from "./components/Content_Reporting/Game_Report/GameReport";
import TopStreamersReport from "./components/Content_Reporting/Top_Streamers_Report/TopStreamersReport";
import TopStreamerVideoReport from "./components/Content_Reporting/Top_Streamer_Video_Report/TopStreamerVideoReport";
import TopStreamerReportOld from "./components/Content_Reporting/Top_Streamers_Report_Old/TopStreamerReportOld";
import LivestreamDurationReport from "./components/Content_Reporting/Livestream_Duration_Report/LivestreamDurationReport";
import CoinsReport from "./components/Content_Reporting/Coins_Report/CoinsReport";
import AddNewGiveaway from "./components/Giveaway/Add_New_Giveaway/AddNewGiveaway";
import GiveawayWinner from "./components/Giveaway/Giveaway_Winner_Details/GiveawayWinner";
import UploadApk from "./components/Upload_APK/UploadApk";
import Upcoming from "./components/Tournaments/Upcoming/Upcoming";
import Previous from "./components/Tournaments/Previous/Previous";
import Cancelled from "./components/Tournaments/Cancelled/Cancelled";
import LiveEvents from "./components/Tournaments/Live/LiveEvents";
import EndedEvents from "./components/Tournaments/Ended/EndedEvents";
import AddEvent from "./components/AddEvent/AddEvent";
import AbortStream from "./components/Abort_Stream/AbortStream";
import Monetization from "./components/Monetization/Monitization";
import MetaTags from "./components/Meta_Tags/MetaTags";
import EditMetaTags from "./components/Meta_Tags/EditMetaTags";
import ShowMetaTags from "./components/Meta_Tags/ShowMetaTags";
import AddMiniGames from "./components/Mini Games/AddMiniGames";
import Sidebar from "./components/Sidebar/MainSidebar/Sidebar";
import DayWiseGamesRecords from "./components/DayWiseGamesRecords/DayWiseGamesRecords";
import { QueryClient, QueryClientProvider } from "react-query";
import StreamersMeta from "./components/StreamersMeta/StreamersMeta";
import EditstreamersMeta from "./components/StreamersMeta/EditstreamersMeta";
import VideosMeta from "./components/VideosMeta/VideosMeta";
import EditvideosMeta from "./components/VideosMeta/EditvideosMeta";
import AllminiGames from "./components/Mini Games/AllminiGames";
import EditminiGames from "./components/Mini Games/EditminiGames";
import ZeroSearchReport from "./components/MiniGamesSearchReport/ZeroSearchReport";
import TopSearchRepport from "./components/MiniGamesSearchReport/TopSearchRepport";
import RecomendedMiniGames from "./components/Mini Games/RecomendedMiniGames";
import HourlySearchReport from "./components/MiniGamesSearchReport/HourlySearchReport";
import CoinSystemReport from "./components/Coin_System_Report/CoinSystemReport";
import UserCoinEarn from "./components/Coin_System_Report/UserCoinEarn";
import SingleUserCoinReport from "./components/Coin_System_Report/SingleUserCoinReport";
import SingleDayCoinReport from "./components/Coin_System_Report/SingleDayCoinReport";
import AllGamesDesc from "./components/GamesDescription/AllGamesDesc";
import EditGamesDesc from "./components/GamesDescription/EditGamesDesc";
import TextEditot from "./components/GamesDescription/TextEditot";

const queryClient = new QueryClient();

function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  // useEffect(()=>{
  //   let getItem = getToken();
  //   if(getItem == 'true')
  //   {
  //     setLoggedIn(true);
  //   }
  // },[])

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Login} exact />
            <>
              <Sidebar />
              <ProtectedRoutes path="/dashboard" component={Dashboard} />
              <ProtectedRoutes path="/usersdata" component={Allusers} />
              <ProtectedRoutes path="/addUser" component={Newuser} />
              <ProtectedRoutes path="/editUser" component={Edituser} />
              <ProtectedRoutes path="/exe_report" component={ExecutiveReport} />
              <ProtectedRoutes
                path="/live_videos"
                component={LivestreamVideoTable}
              />
              <ProtectedRoutes
                path="/uploaded_videos"
                component={UploadedVideoTable}
              />
              <ProtectedRoutes
                path="/shorts_videos"
                component={ShortsVideoTable}
              />
              <ProtectedRoutes
                path="/user_status"
                component={UserVerification}
              />
              <ProtectedRoutes
                path="/adduser_verification"
                component={AddverificationRecord}
              />
              <ProtectedRoutes
                path="/reported_videos"
                component={VideocommentReports}
              />
              <ProtectedRoutes path="/addFAQ" component={AddNewFaq} />
              <ProtectedRoutes path="/allFAQ" component={AllFaq} />
              <ProtectedRoutes path="/FAQ_Desc" component={FaqDescription} />
              <ProtectedRoutes path="/addBlog" component={AddNewBlog} />
              <ProtectedRoutes path="/blog_Desc" component={BlogDescription} />
              <ProtectedRoutes path="/allBlogs" component={AllBlogs} />
              <ProtectedRoutes path="/editBlog" component={Editblog} />
              <ProtectedRoutes
                path="/editBlog_Desc"
                component={EditblogDescription}
              />
              <ProtectedRoutes path="/editFaq" component={EditFaq} />
              <ProtectedRoutes
                path="/editFaq_Desc"
                component={EditFaqDescription}
              />
              <ProtectedRoutes path="/addNewGame" component={AddGame} />
              <ProtectedRoutes path="/fetchGames" component={GameCategories} />
              <ProtectedRoutes path="/editGames" component={EditGames} />
              <ProtectedRoutes
                path="/manageGamers"
                component={AddManageGamers}
              />
              <ProtectedRoutes
                path="/uploadedVideoReport"
                component={UploadedVideosReport}
              />
              <ProtectedRoutes
                path="/livestreamVideoReport"
                component={LivestreamVideoReport}
              />
              <ProtectedRoutes
                path="/deactivatedVideoReport"
                component={DeactivatedVideoReport}
              />
              <ProtectedRoutes
                path="/deactivatedLivestreamReport"
                component={DeactivatedLivestreamReport}
              />
              <ProtectedRoutes path="/gamereport" component={GameReport} />
              <ProtectedRoutes
                path="/streamers_reports"
                component={TopStreamersReport}
              />
              <ProtectedRoutes
                path="/top_stream_reports"
                component={TopStreamerVideoReport}
              />
              <ProtectedRoutes
                path="/top_streamers_report_old"
                component={TopStreamerReportOld}
              />
              <ProtectedRoutes
                path="/livestream_duration"
                component={LivestreamDurationReport}
              />
              <ProtectedRoutes path="/coins_report" component={CoinsReport} />
              <ProtectedRoutes
                path="/add_giveaway"
                component={AddNewGiveaway}
              />
              <ProtectedRoutes
                path="/winner_deatils"
                component={GiveawayWinner}
              />
              <ProtectedRoutes path="/uploadapk" component={UploadApk} />
              <ProtectedRoutes
                path="/eventreportupcoming"
                component={Upcoming}
              />
              <ProtectedRoutes
                path="/eventreportprevious"
                component={Previous}
              />
              <ProtectedRoutes
                path="/eventreportcancelled"
                component={Cancelled}
              />
              <ProtectedRoutes path="/liveEventreport" component={LiveEvents} />
              <ProtectedRoutes
                path="/endedEventreport"
                component={EndedEvents}
              />
              <ProtectedRoutes path="/addEvent" component={AddEvent} />
              <ProtectedRoutes path="/abortStream" component={AbortStream} />
              <ProtectedRoutes path="/monetization" component={Monetization} />
              <ProtectedRoutes path="/meta_tags" component={MetaTags} />
              <ProtectedRoutes
                path="/edit_meta_tags"
                component={EditMetaTags}
              />
              <ProtectedRoutes
                path="/show_meta_tags"
                component={ShowMetaTags}
              />
              <ProtectedRoutes
                path="/add_mini_games"
                component={AddMiniGames}
              />
              <ProtectedRoutes
                path="/all_mini_games"
                component={AllminiGames}
              />
              <ProtectedRoutes
                path="/edit_mini_games"
                component={EditminiGames}
              />
              <ProtectedRoutes
                path="/recomended_mini_games"
                component={RecomendedMiniGames}
              />
              <ProtectedRoutes
                path="/hourly-search"
                component={HourlySearchReport}
              />
              <ProtectedRoutes
                path="/mini-games-analytics"
                component={DayWiseGamesRecords}
              />
              <ProtectedRoutes
                path="/streamers-meta"
                component={StreamersMeta}
              />
              <ProtectedRoutes
                path="/edit-streamers-meta"
                component={EditstreamersMeta}
              />
              <ProtectedRoutes path="/videos-meta" component={VideosMeta} />
              <ProtectedRoutes
                path="/edit-videos-meta"
                component={EditvideosMeta}
              />
              <ProtectedRoutes
                path="/zero-search-data"
                component={ZeroSearchReport}
              />
              <ProtectedRoutes
                path="/top-search-data"
                component={TopSearchRepport}
              />
              <ProtectedRoutes
                path="/coins-reports"
                component={CoinSystemReport}
              />
              <ProtectedRoutes
                path="/user-coin-earn"
                component={UserCoinEarn}
              />
              <ProtectedRoutes path="/single-user-coin-earn" component={SingleUserCoinReport} />
              <ProtectedRoutes path="/single-day-coin-earn" component={SingleDayCoinReport} />
              <ProtectedRoutes path="/all-games-desc" component={AllGamesDesc} />
              <ProtectedRoutes path="/edit-games-desc" component={EditGamesDesc} />
              <ProtectedRoutes path="/text" component={TextEditot} />



            </>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
