import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";

function ShowMetaTags() {
  const [data, setData] = useState([]);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getMetaTags();
  }, []);

  const getMetaTags = async () => {
    const responce = await axios.get(
      "https://api.gamesee.tv/v2/metadescriptionsGet"
    );
    if (responce?.data?.Success === 1) {
      setData(responce?.data?.data?.select);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Row>
            <Col>
              <Card className="card">
                <Card.Header className="heading">All Meta Tags</Card.Header>
                <Card.Body className="body">
                  <MaterialTable
                    data={data}
                    icons={tableIcons}
                    columns={[
                      {
                        title: "#",
                        field: "tableData.id",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Page Name",
                        field: "page_id",
                        lookup: {
                          0: "Home",
                          1: "Game Categories",
                          2: "All Blogs",
                          3: "About Us",
                          4: "Terms Of Use",
                          5: "Privacy Policy",
                          6: "FAQ's",
                          7: "Favorite Games",
                          8: "Search",
                          9: "Recently PLayed"
                        },
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                        },
                      },
                      {
                        title: "Meta Title",
                        field: "title",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Meta Description",
                        field: "meta_description",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Meta Keywords",
                        field: "meta_keywords",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "OG Title",
                        field: "ogtitle",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "OG URL",
                        field: "ogurl",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "OG Description",
                        field: "ogdescription",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "OG Image URL",
                        field: "ogimageurl",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Edit",
                        render: (rowData) => (
                          <Link
                            to={{ pathname: "/edit_meta_tags", state: rowData }}
                          >
                            <Button
                              className="table-button"
                              variant="primary"
                              size="md"
                            >
                              Edit
                            </Button>
                          </Link>
                        ),
                      },
                    ]}
                    options={{
                      showTitle: false,
                      exportButton: true,
                      paging: true,
                      pageSizeOptions: [5, 20, 40, 70],
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ShowMetaTags;
