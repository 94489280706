import React, { useState, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CKEditor from "ckeditor4-react-advanced";
import axios from "axios";

const EditFaqDescription = (props) => {
  const history = useHistory();
  const [category, setCategory] = useState(props.location.state.category);
  const [title, setTitle] = useState(props.location.state.title);
  const [faqVideo, setFaqVideo] = useState(props.location.state.faqVideo);
  const [language, setLanguage] = useState(props.location.state.language);
  const [relatedFaq, setRelatedFaq] = useState(props.location.state.relatedFaq);
  const [shortDescription, setShortDescription] = useState(
    props.location.state.shortDescription
  );
  const [editorValue, setEditorValue] = useState(
    props.location.state.longDescription
  );
  console.log("propls...", props);

  const add = () => {
    console.log("add fnc");
    axios
      .post("https://api.gamesee.tv/v2/edit-faqs", {
        category: category,
        title: title,
        faqVideo: faqVideo,
        language: language,
        relatedFaq: relatedFaq.toString(),
        longDescription: editorValue,
        shortDescription: shortDescription,
      })
      .then((res) => {
        console.log(res);
        if (res.data.Success == 1) {
          toast.success("FAQ Added Successfully!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push({
              pathname: "/allFAQ",
            });
          }, 3000);
        }
      });
  };
  // console.log(props.location.state);
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">FAQ Description</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <CKEditor
                    data={shortDescription}
                    onChange={(evt) => setEditorValue(evt.editor.getData())}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                className="table-button"
                onClick={() => add()}
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(EditFaqDescription);
