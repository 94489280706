import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import axios from "axios";
import CKEditor from "ckeditor4-react-advanced";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditblogDescription = (props) => {
  const history = useHistory();
  const data = props.history.location.state;
  console.log("data......", data);
  const [editorValue, setEditorValue] = useState(data.blogDesc);
  // const [publishDateVal, setPublishDateVal] = useState(
  //   moment(data.publishDate).toISOString()
  // );
  // console.log("Props on Edit Blog Description...", data);
  // console.log("publishDateVal", publishDateVal);
  // console.log("Props.....", data);

  // console.log(data.blogId);
  const add = async () => {
    const formData = new FormData();
    formData.append("blogId", data.blogId);
    formData.append("blogDesc", editorValue);
    formData.append("blogImage", data.blogImage);
    formData.append("author", data.author);
    formData.append("readTime", data.readTime);
    formData.append("publishDate", data.publishDate);
    formData.append("relatedBlog", data.relatedBlog);
    formData.append("meta_title", data.meta_tile);
    formData.append("meta_desc", data.meta_desc);

    //  for (var value of formData.values()) {
    //     console.log("formdata.....",value);
    //  }

    axios
      .post("https://api.gamesee.tv/v2/new-blog-edit", formData)
      .then((res) => {
        if (res.data.Success == 1) {
          toast.success("Blog Added Successfully!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push({
              pathname: "/allBlogs",
            });
          }, 3000);
        }
      });
  };

  return (
    <>
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Edit Blog Description</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <CKEditor
                    data={editorValue}
                    onChange={(evt) => setEditorValue(evt.editor.getData())}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => add()}
                className="table-button"
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default EditblogDescription;
