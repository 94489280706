import React, { useState } from "react";
import "./Sidebar.css";
import SidebarRow from "../SidebarRow/SidebarRow";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Link } from "react-router-dom";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined";
import GamesIcon from "@mui/icons-material/Games";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../../../Assets/logo.png";
import { Navbar, Offcanvas, Nav, Container, Button } from "react-bootstrap";
import { removeUserSession } from "../../../Utils/Common/Common";
import { useHistory } from "react-router-dom";

const Sidebar = () => {
  const [showPanelUsers, setShowPanelUsers] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showVerificationContent, setShowVerificationContent] = useState(false);
  const [showGames, setShowGames] = useState(false);
  const [showContentReporting, setShowContentReporting] = useState(false);
  const [showBlogs, setShowBlogs] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const [showGiveaway, setShowGiveaway] = useState(false);
  const [showUploadApk, setShowUploadApk] = useState(false);
  const [showTournament, setShowTournament] = useState(false);
  const [showMeta, setShowMeta] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showMiniGames, setShowMiniGames] = useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [showCoinsData, setShowCoinsData] = useState(false);
  const [showDescData, setShowDescData] = useState(false);
  const history = useHistory();

  const logout = () => {
    removeUserSession();
    history.push("/");
  };
  return (
    <>
      <Navbar fixed="top" bg="dark" variant="dark" expand={false}>
        <Container fluid>
          <div className="left-items">
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Link to="/dashboard">
              <Navbar.Brand>
                <img src={logo} width={150} />
              </Navbar.Brand>
            </Link>
          </div>
          <div className="right-items">
            <Navbar.Brand>
              <Button
                onClick={() => setShowProfile(!showProfile)}
                className="btn-sm user-btn"
              >
                {sessionStorage.getItem("username")}
              </Button>
            </Navbar.Brand>
            {/* {sessionStorage.getItem('username')} */}
          </div>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img className="modal-logo" src={logo} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <div
                  className="dropdownList"
                  onClick={() => setShowSearchData(!showSearchData)}
                >
                  <SidebarRow
                    Icon={AssessmentOutlinedIcon}
                    title="Mini Games Search Reports"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showSearchData && (
                  <>
                    <Link className="dropdownList-items" to="/zero-search-data">
                      <SidebarRow
                        Icon={AssessmentOutlinedIcon}
                        title="Zero Search Results"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/top-search-data">
                      <SidebarRow
                        Icon={AssessmentOutlinedIcon}
                        title="Top Search Results"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/hourly-search">
                      <SidebarRow
                        Icon={GamesIcon}
                        title="Hourly Search Report"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowMiniGames(!showMiniGames)}
                >
                  <SidebarRow
                    Icon={GamesIcon}
                    title="Mini Games"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showMiniGames && (
                  <>
                    <Link className="dropdownList-items" to="/all_mini_games">
                      <SidebarRow
                        Icon={GamesIcon}
                        title="All Mini Games"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/add_mini_games">
                      <SidebarRow
                        Icon={GamesIcon}
                        title="Add Mini Games"
                        status={true}
                      />
                    </Link>
                    <Link
                      className="dropdownList-items"
                      to="/mini-games-analytics"
                    >
                      <SidebarRow
                        Icon={GamesIcon}
                        title="Mini Games Report"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/recomended_mini_games">
                      <SidebarRow
                        Icon={GamesIcon}
                        title="Recomended Mini Games"
                        status={true}
                      />
                    </Link>
                  </>
                )}

                <div
                  className="dropdownList"
                  onClick={() => setShowCoinsData(!showCoinsData)}
                >
                  <SidebarRow
                    Icon={MonetizationOnOutlinedIcon}
                    title="Mini Games Coins Reports"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showCoinsData && (
                  <>
                    <Link className="dropdownList-items" to="/coins-reports">
                      <SidebarRow
                        Icon={AssessmentOutlinedIcon}
                        title="Coins Report"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/user-coin-earn">
                      <SidebarRow
                        Icon={AssessmentOutlinedIcon}
                        title="User Coins Earnings Daily Report"
                        status={true}
                      />
                    </Link>
                  </>
                )}

                <div
                  className="dropdownList"
                  onClick={() => setShowDescData(!showDescData)}
                >
                  <SidebarRow
                    Icon={VideogameAssetOutlinedIcon}
                    title="Games Description"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showDescData && (
                  <>
                    <Link className="dropdownList-items" to="/all-games-desc">
                      <SidebarRow
                        Icon={VideogameAssetOutlinedIcon}
                        title="All Games Description"
                        status={true}
                      />
                    </Link>
                  </>
                )}

                <Link className="sidebarItems" to="/dashboard">
                  <SidebarRow
                    Icon={SpeedOutlinedIcon}
                    status={true}
                    title="Dashboard"
                  />
                </Link>
                <div
                  className="dropdownList"
                  onClick={() => setShowPanelUsers(!showPanelUsers)}
                >
                  <SidebarRow
                    Icon={ComputerOutlinedIcon}
                    title="Panel Users"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showPanelUsers && (
                  <>
                    <Link className="dropdownList-items" to="/addUser">
                      <SidebarRow
                        Icon={PersonAddAltOutlinedIcon}
                        title="New Users"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/usersdata">
                      <SidebarRow
                        Icon={PeopleOutlinedIcon}
                        title="All Users"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/exe_report">
                      <SidebarRow
                        Icon={AssignmentOutlinedIcon}
                        title="Executive Report"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowContent(!showContent)}
                >
                  <SidebarRow
                    Icon={GridOnOutlinedIcon}
                    title="Content"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showContent && (
                  <>
                    <Link className="dropdownList-items" to="/uploaded_videos">
                      <SidebarRow
                        Icon={WhatshotOutlinedIcon}
                        title="Uploaded"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/live_videos">
                      <SidebarRow
                        Icon={WhatshotOutlinedIcon}
                        title="LiveStream"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/shorts_videos">
                      <SidebarRow
                        Icon={WhatshotOutlinedIcon}
                        title="Shorts Video"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() =>
                    setShowVerificationContent(!showVerificationContent)
                  }
                >
                  <SidebarRow
                    Icon={GridOnOutlinedIcon}
                    title="Verification Content"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showVerificationContent && (
                  <>
                    {/* <Link className='dropdownList-items' to='/user_status'><SidebarRow Icon={ExtensionOutlinedIcon} title='User Verification Status' status={true}/></Link> */}
                    <Link
                      className="dropdownList-items"
                      to="/adduser_verification"
                    >
                      <SidebarRow
                        Icon={ExtensionOutlinedIcon}
                        title="User Verified"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/reported_videos">
                      <SidebarRow
                        Icon={ExtensionOutlinedIcon}
                        title="Video Comments Reports"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowGames(!showGames)}
                >
                  <SidebarRow
                    Icon={ViewComfyOutlinedIcon}
                    title="Games"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showGames && (
                  <>
                    <Link className="dropdownList-items" to="/addNewGame">
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Add New Games"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/fetchGames">
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Games Categories"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/manageGamers">
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Add/Manage Gamers"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowContentReporting(!showContentReporting)}
                >
                  <SidebarRow
                    Icon={FormatListNumberedRtlOutlinedIcon}
                    title="Content Reporting"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showContentReporting && (
                  <>
                    <Link
                      className="dropdownList-items"
                      to="/uploadedVideoReport"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Uploaded"
                        status={true}
                      />
                    </Link>
                    <Link
                      className="dropdownList-items"
                      to="/livestreamVideoReport"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Livestream"
                        status={true}
                      />
                    </Link>
                    <Link
                      className="dropdownList-items"
                      to="/deactivatedVideoReport"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Deactivated Videos"
                        status={true}
                      />
                    </Link>
                    <Link
                      className="dropdownList-items"
                      to="/deactivatedLivestreamReport"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Deactivated Livestreams"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/gamereport">
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Game Report"
                        status={true}
                      />
                    </Link>
                    {/* <Link className='dropdownList-items' to='/streamers_reports'><SidebarRow Icon={ViewComfyOutlinedIcon} title='Top Streamers Report' status={true}/></Link>
              <Link className='dropdownList-items' to='/top_stream_reports'><SidebarRow Icon={ViewComfyOutlinedIcon} title='Top Streamer Videos Report' status={true}/></Link> */}
                    <Link
                      className="dropdownList-items"
                      to="/top_streamers_report_old"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Top Streamer Videos Report Old"
                        status={true}
                      />
                    </Link>
                    <Link
                      className="dropdownList-items"
                      to="/livestream_duration"
                    >
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Livestream Duration Report"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/coins_report">
                      <SidebarRow
                        Icon={ViewComfyOutlinedIcon}
                        title="Coins Report"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowBlogs(!showBlogs)}
                >
                  <SidebarRow
                    Icon={NewspaperOutlinedIcon}
                    title="Blogs"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showBlogs && (
                  <>
                    <Link className="dropdownList-items" to="/addBlog">
                      <SidebarRow
                        Icon={NewspaperOutlinedIcon}
                        title="Add New Blogs"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/allBlogs">
                      <SidebarRow
                        Icon={NewspaperOutlinedIcon}
                        title="All Blogs"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowFaq(!showFaq)}
                >
                  <SidebarRow
                    Icon={NewspaperOutlinedIcon}
                    title="FAQs"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showFaq && (
                  <>
                    <Link className="dropdownList-items" to="/addFAQ">
                      <SidebarRow
                        Icon={NewspaperOutlinedIcon}
                        title="Add New FAQs"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/allFAQ">
                      <SidebarRow
                        Icon={NewspaperOutlinedIcon}
                        title="All FAQs"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowGiveaway(!showGiveaway)}
                >
                  <SidebarRow
                    Icon={CardGiftcardOutlinedIcon}
                    title="Giveaway"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showGiveaway && (
                  <>
                    <Link className="dropdownList-items" to="/add_giveaway">
                      <SidebarRow
                        Icon={CardGiftcardOutlinedIcon}
                        title="Add New Giveaway"
                        status={true}
                      />
                    </Link>
                    <Link className="dropdownList-items" to="/winner_deatils">
                      <SidebarRow
                        Icon={CardGiftcardOutlinedIcon}
                        title="Check Winner Details"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowMeta(!showMeta)}
                >
                  <SidebarRow
                    Icon={CardGiftcardOutlinedIcon}
                    title="Meta Tags"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showMeta && (
                  <>
                    {/* <Link className="dropdownList-items" to="/meta_tags">
                      <SidebarRow
                        Icon={BookmarkAddedIcon}
                        status={true}
                        title="Add Meta Tags"
                      />
                    </Link> */}
                    <Link className="dropdownList-items" to="/show_meta_tags">
                      <SidebarRow
                        Icon={BookmarkAddedIcon}
                        status={true}
                        title="Show Meta Tags"
                      />
                    </Link>
                    {/* <Link className="dropdownList-items" to="/streamers-meta">
                      <SidebarRow
                        Icon={BookmarkAddedIcon}
                        status={true}
                        title="Stremers Meta Tags"
                      />
                    </Link> */}
                    {/* <Link className="dropdownList-items" to="/videos-meta">
                      <SidebarRow
                        Icon={BookmarkAddedIcon}
                        status={true}
                        title="Videos Meta Tags"
                      />
                    </Link> */}
                  </>
                )}
                <div
                  className="dropdownList"
                  onClick={() => setShowUploadApk(!showUploadApk)}
                >
                  <SidebarRow
                    Icon={AndroidOutlinedIcon}
                    title="Upload APK"
                    status={true}
                  />
                  <ExpandMoreOutlinedIcon />
                </div>
                {showUploadApk && (
                  <>
                    <Link className="dropdownList-items" to="/uploadapk">
                      <SidebarRow
                        Icon={AndroidOutlinedIcon}
                        title="Add APK"
                        status={true}
                      />
                    </Link>
                  </>
                )}
                {/* <div className='dropdownList' onClick={()=>setShowTournament(!showTournament)}>
            <SidebarRow Icon={InsertChartOutlinedRoundedIcon} title='Tournament' status={true}/>
            <ExpandMoreOutlinedIcon />
          </div>
          {showTournament &&
          <>
            <Link className='dropdownList-items' to='/eventreportupcoming'><SidebarRow Icon={UpcomingOutlinedIcon} title='Upcoming' status={true}/></Link>
            <Link className='dropdownList-items' to='/eventreportprevious'><SidebarRow Icon={UndoOutlinedIcon} title='Previous' status={true}/></Link>
            <Link className='dropdownList-items' to='/eventreportcancelled'><SidebarRow Icon={DoNotDisturbOnOutlinedIcon} title='Cancelled' status={true}/></Link>
            <Link className='dropdownList-items' to='/liveEventreport'><SidebarRow Icon={LiveTvOutlinedIcon} title='Live' status={true}/></Link>
            <Link className='dropdownList-items' to='/endedEventreport'><SidebarRow Icon={AssignmentTurnedInOutlinedIcon} title='Ended' status={true}/></Link>
          </>
          }
            <Link className='sidebarItems' to='/addEvent'><SidebarRow Icon={TodayOutlinedIcon} status={true} title='Add Event' /></Link> */}
                <Link className="sidebarItems" to="/abortStream">
                  <SidebarRow
                    Icon={CancelPresentationIcon}
                    status={true}
                    title="Abort Stream"
                  />
                </Link>
                <Link className="sidebarItems" to="/monetization">
                  <SidebarRow
                    Icon={LocalAtmIcon}
                    status={true}
                    title="Monetization"
                  />
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {showProfile && (
        <div className="avatar-dropdown">
          <h4
            style={{ color: "#fff" }}
            className="avatar-item"
            onClick={() => logout()}
          >
            <LogoutIcon
              style={{ fontSize: 30, paddingRight: 10, color: "#fff" }}
            />
            Logout
          </h4>
        </div>
      )}
    </>
  );
};

export default Sidebar;
