import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AddGame = () => {
  const [gameType, setGameType] = useState(1);
  const [gameName, setGameName] = useState("");
  const [iTunesId, setiTunesId] = useState("");
  const [bundle, setBundle] = useState("");
  const [packageName, setPackageName] = useState("");
  const [gameImage, setGameImage] = useState();
  const [gameCoverImage, setGameCoverImage] = useState();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [orientation, setOrientation] = useState("0");

  const submit = () => {
    if (
      gameName.length &&
      iTunesId.length &&
      bundle.length &&
      packageName.length != 0
    ) {
      const formData = new FormData();
      formData.append("gameType", gameType);
      formData.append("gameName", gameName);
      formData.append("gameImage", gameImage);
      formData.append("gameCoverImage", gameCoverImage);
      formData.append("itunesId", parseInt(iTunesId));
      formData.append("bundle", bundle);
      formData.append("metaTitle", metaTitle);
      formData.append("metaDesc", metaDescription);
      formData.append("androidPackageName", packageName);
      formData.append("gameOrentitation", orientation);

      for (var value of formData.values()) {
        console.log("formdata.....", value);
      }

      axios
        .post("https://api.gamesee.tv/v2/panel-add-new-game", formData)
        .then((res) => {
          console.log(res);
          if (res.data.Success === 1) {
            console.log(res);
            toast.success("New game Added successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
          }
        });
    } else {
      toast.warn(
        "Game Name, iTunes ID, Bundle & Android Package Name are mandatory fields  ",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add New Games</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Type</Form.Label>
                    <Form.Select
                      value={gameType}
                      onChange={(e) => setGameType(e.target.value)}
                    >
                      <option value="1">Mobile</option>
                      <option value="2">Desktop</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>orientation</Form.Label>
                    <Form.Select
                      value={orientation}
                      onChange={(e) => setOrientation(e.target.value)}
                    >
                      <option value="0">None</option>
                      <option value="1">Potrait</option>
                      <option value="2">Landsacpe</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Game Name"
                      onChange={(e) => setGameName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>iTunes ID *</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="iTunes ID"
                      onChange={(e) => setiTunesId(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Bundle *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Bundle"
                      onChange={(e) => setBundle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Android Package Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Android Package Name"
                      onChange={(e) => setPackageName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Image *</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setGameImage(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Cover Image</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setGameCoverImage(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Button
                className="submit-btn"
                onClick={() => submit()}
                variant="primary"
              >
                Submit
              </Button>
              <Button className="cancel-btn" variant="danger">
                Cancel
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AddGame);
