import React, { useState } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import Loader from "../Spinner/Spinner";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function MetaTags() {
  const [page, setPage] = useState(0);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeywords, setMetakeywords] = useState("");
  const [ogTitle, setOgitle] = useState("");
  const [ogUrl, setOgUrl] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [ogImgurl, setOgImgUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const body = {
      page_id: page,
      title: metaTitle,
      meta_description: metaDesc,
      meta_keywords: metaKeywords,
      ogtitle: ogTitle,
      ogurl: ogUrl,
      ogimageurl: ogImgurl,
      ogdescription: ogDescription,
    };
    const responce = await axios.post(
      `https://api.gamesee.tv/v2/metadescriptionspostinsert`,
      body
    );
    if (responce?.data?.Success === 1) {
      setLoading(false);
      toast.success(responce.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.warn(responce.data.error.error_message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add Meta Tags</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Select Page <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Select onChange={(e) => setPage(e.target.value)}>
                      <option value={0}>Home</option>
                      <option value={1}>Game Categories</option>
                      <option value={2}>Videos</option>
                      <option value={3}>Shots</option>
                      <option value={4}>Tournaments</option>
                      <option value={5}>Play Mini Games</option>
                      <option value={6}>All Blogs</option>
                      <option value={7}>About Us</option>
                      <option value={8}>Terms of Use</option>
                      <option value={9}>Privacy Policy</option>
                      <option value={10}>FAQs</option>
                      <option value={11}>Streamers</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      onChange={(e) => setMetaDesc(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Keywords</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Title</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Url</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgUrl(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Image Url</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgImgUrl(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              {loading == true ? (
                <Loader />
              ) : (
                <Button
                  className="submit-btn"
                  onClick={() => submit()}
                  variant="primary"
                >
                  Submit
                </Button>
              )}
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default MetaTags;
