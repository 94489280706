import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import axios from "axios";
import CKEditor from "ckeditor4-react-advanced";

const FaqDescription = (props) => {
  const data = props.location.state;
  const [title, setTitle] = useState(data.title);
  const [shortDescription, setShortDescription] = useState(
    data.shortDescription
  );
  const [relatedFaq, setRelatedFaq] = useState(data.relatedFaq);
  const [language, setLanguage] = useState(data.language);
  const [faqVideo, setFaqVideo] = useState(data.faqVideo);
  const [category, setCategory] = useState(data.category);
  const history = useHistory();
  const [editorValue, setEditorValue] = useState("");

  console.log("props value......", props.location.state);
  const add = () => {
    const body = {
      category: category,
      title: title,
      faqVideo: faqVideo,
      language: language,
      relatedFaq: relatedFaq.toString(),
      longDescription: editorValue,
      shortDescription: shortDescription,
    };
    console.log(editorValue);
    axios.post("https://api.gamesee.tv/v2/add-faqs", body).then((res) => {
      console.log(res);
      if (res.data.Success == 1) {
        toast.success("FAQ Added Successfully!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          history.push({
            pathname: "/addFAQ",
          });
        }, 2000);
      } else {
        toast.error("There was Error adding FAQ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    // axios
    //   .post("https://api.gamesee.tv/v2/add-faqs", {
    //     category: props.location.state.category,
    //     title: props.location.state.title,
    //     faqVideo: props.location.state.faqVideo,
    //     language: props.location.state.language,
    //     relatedFaq: props.location.state.relatedFaq,
    //     longDescription: editorValue,
    //     shortDescription: props.location.state.shortDescription,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (res.data.Success == 1) {
    //       history.push("/allFAQ");
    //     }
    //   });
    // console.log(props.location.state.category,props.location.state.title,props.location.state.faqVideo,props.location.state.language,props.location.state.relatedFaq,editorValue,props.location.state.shortDescription)
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">FAQ Description</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  {/* {loader && <Loader />}
                  <Editor
                    onEditorChange={(e) => setEditorValue(e)}
                    apiKey="28fhk7hzo6ykr94ms8w86klrpqbgznxu035b8l41w72duezn"
                    onInit={() => setLoader(false)}
                    initialValue="Add FAQ Description"
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
                      ],
                      //    imagetools_cors_hosts: ['picsum.photos'],
                      toolbar:
                        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  /> */}
                  <CKEditor
                    data={"Enter FAQ Description"}
                    onChange={(evt) => setEditorValue(evt.editor.getData())}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                className="table-button"
                onClick={() => add()}
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(FaqDescription);
