//Under Second Content tab User Verified
import React, { useState, forwardRef } from 'react';
import './addverificationRecord.css';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Sidebar from '../../Sidebar/MainSidebar/Sidebar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const AddverificationRecord = () => {
    const [requiredFollowers, setRequiredFollowers] = useState('');
    const [requiredStreamingHours, setRequiredStreamingHours] = useState('');

    const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

    const data = [
    { ID: "2007415", Followers: '100', Streaming_hours: "100", Updated_By:"Vikas", Created_Date:"14-Apr-2021", Updated_Date: "14-Apr-2021", Status:<button className='btn btn-danger btn-sm'>Previous</button>},
    { ID: "2007212", Followers: '101', Streaming_hours: "101", Updated_By:"Vikas", Created_Date:"14-Apr-2021", Updated_Date: "14-Apr-2021", Status:<button className='btn btn-danger btn-sm'>Previous</button>},
    { ID: "2012134", Followers: '11', Streaming_hours: "12", Updated_By:"Vikas", Created_Date:"14-Apr-2021", Updated_Date: "14-Apr-2021", Status:<button className='btn btn-danger btn-sm'>Previous</button>},
    { ID: "2434137", Followers: '200', Streaming_hours: "250", Updated_By:"Vikas", Created_Date:"14-Apr-2021", Updated_Date: "14-Apr-2021", Status:<button className='btn btn-danger btn-sm'>Previous</button>},
    { ID: "2438561", Followers: '1212', Streaming_hours: "132123", Updated_By:"Vikas", Created_Date:"30-Nov--0001", Updated_Date: "14-Apr-2021", Status:<button className='btn btn-success btn-sm'>Latest</button>},
     ]



    const onSubmit = () =>{
        console.log(requiredFollowers,requiredStreamingHours);
    }

    const resetForm = () => {
        document.getElementById("myForm").reset();
    }

    return (
        <>
            <Sidebar/>
            <div className='content'>
                <Container>
                    <Card>
                        <Card.Header className='heading'>Add Verification Record</Card.Header>
                            <Card.Body>
                                <Col className='main'>
                                    <Form id="myForm">
                                    <Form.Group className="mb-3">
                                    <Form.Label>Required Followers</Form.Label>
                                    <Form.Control type="number" placeholder="Number" onChange={e=> setRequiredFollowers(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                    <Form.Label>Required Streaming Hours</Form.Label>
                                    <Form.Control type="number" placeholder="Number" onChange={e=> setRequiredStreamingHours(e.target.value)}/>
                                    </Form.Group>
                                    </Form>
                                </Col>
                            </Card.Body>
                        <Card.Footer className='footer'>
                            <Button className='submit-btn' onClick={()=>onSubmit()} variant="primary">
                                Submit
                            </Button>
                            <Button onClick={()=>resetForm()} className='cancel-btn' variant="danger">
                                Cancel
                            </Button>
                        </Card.Footer>
                    </Card>
                    <Col className='table-section'>
                    <Card>
                        <Card.Header className='heading'>User's Verification Table</Card.Header>
                        <Card.Body>
                        <MaterialTable
                            icons={tableIcons}
                            title={'All Users'}
                            columns={[
                              {title:'ID',field:'ID',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Followers',field:'Followers',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Required Streaming Hours',field:'Streaming_hours',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Updated By',field:'Updated_By',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Created Date',field:'Created_Date',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Updated Date',field:'Updated_Date',cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%'},
                              {title:'Status', field:'Status', cellStyle:{borderRight:'1px solid #e0e0e0'},width:'2%',}
                            ]}

                                data={data}
                                  options={{
                                    exportButton:true,
                                  }}
                                />
                        </Card.Body>
                    </Card> 
                </Col>            
            </Container>
            </div>
        </>             
            
    )
}

export default AddverificationRecord
