//Under First Content tab Uploaded Videos
import React, { forwardRef, useState, useEffect } from "react";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./livestreamVideoReport.css";
import Loader from "../../Spinner/Spinner";
import axios from "axios";

const LivestreamVideoTable = () => {
  const [data, setData] = useState([]);
  const [selectValue, setSelectValue] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState("");
  const [trimVideoId, setTrimVideoId] = useState("");
  const [deactivationReason, setDeactivationReason] = useState("1");
  const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [colorChange, setColorChange] = useState("");
  const [pendingVideoCount, setPendingVideoCount] = useState("");
  const [activeVideoCount, setActiveVideoCount] = useState("");
  const [deactiveVideoCount, setDeactiveVideoCount] = useState("");

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const submit = () => {
    setSubmitted(!submitted);
  };

  useEffect(() => {
    // console.log("genking workin.......");
    getVideos();
    // axios.post('https://api.gamesee.tv/v2/panel-shot-list',
    // {"user_id":1,
    // "type":selectValue,
    // "startDate":startDate,
    // "endDate":endDate})
    // .then((res)=>{
    //   if(res.data.Success == 1)
    //   {
    //     let responce = res.data.data;
    //     setData(responce.selectAllshort);
    //     setChange(selectValue);
    //   }
    // })
  }, [submitted, deactivated, activated]);

  //Deactivate Videos
  const deactivate = async () => {
    setLoading(true);
    const body = {
      videoId: trimVideoId,
      reasonDeactive: deactivationReason,
      actionPerformedBy: userId,
    };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/live-video-isDeative",
        body
      );
      if (responce.data.Success == 1) {
        // console.log(responce);
        setLoading(false);
        setDeactivated(!deactivated);
        setShowModal(false);
        toast.warning("Video Deactivated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // window.location.reload();
    } catch (err) {
      console.log("Error", err);
    }
  };
  //Activate Videos
  const activate = async (id) => {
    setLoading(true);
    const body = {
      videoId: id,
    };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/live-video-isActive",
        body
      );
      // console.log("responce.....", responce);
      if (responce.data.Success == 1) {
        setActivated(!activated);
        setLoading(false);
        toast.success("Video Activated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // window.location.reload();
    } catch (err) {
      console.log("Error", err);
    }
  };
  //Open Modal
  const openModal = (id) => {
    setShowModal(true);
    setTrimVideoId(id);
  };

  const getVideos = async () => {
    console.log(startDate, " ", endDate);
    setLoading(true);
    const body = {
      user_id: 1,
      type: selectValue,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/panel-live-video-list",
        body
      );
      console.log("responce......", responce);
      if (responce.data.Success == 1) {
        setPendingVideoCount(responce.data.data.countPending);
        setDeactiveVideoCount(responce.data.data.countDeactive);
        setActiveVideoCount(responce.data.data.countActive);
        setData(responce.data.data.selectLivevideo);
        setChange(selectValue);
        setLoading(false);
      } else {
        console.log(responce.data.error.error_message);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Reason For Deactivation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Select
                  value={deactivationReason}
                  onChange={(e) => setDeactivationReason(e.target.value)}
                >
                  <option value="1">Technical Issue</option>
                  <option value="2">Irrelevant Content</option>
                  <option value="3">Quality Issues</option>
                  <option value="5">SCR Video</option>
                </Form.Select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                variant="secondary"
                size="md"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="button"
                variant="primary"
                size="md"
                onClick={() => deactivate()}
              >
                Deactivate
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card className="card">
            <Card.Header className="heading">
              Livestream Videos Table
            </Card.Header>
            <Card.Body className="body">
              <Row className="form-row">
                <Col className="cols" md={4} sm={6} xs={12} lg={4}>
                  <Form.Group>
                    <Form.Select
                      value={selectValue}
                      onChange={(e) => setSelectValue(e.target.value)}
                    >
                      <option value="1">
                        Pending Videos ({pendingVideoCount})
                      </option>
                      <option value="3">
                        Deactivate Videos ({deactiveVideoCount})
                      </option>
                      <option value="2">
                        Active Videos ({activeVideoCount})
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="cols" md={4} sm={6} xs={12} lg={3}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" md={4} sm={6} xs={12} lg={3}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" lg={2}>
                  <Form.Group>
                    <Button className="submit-button" onClick={() => submit()}>
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              {loading == true ? (
                <Loader />
              ) : (
                <MaterialTable
                  icons={tableIcons}
                  title={"Livestream Videos"}
                  columns={[
                    {
                      title: "#",
                      field: "tableData.id",
                      render: (rowData) => rowData.tableData.id + 1,
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "ID",
                      field: "id",
                      cellStyle: { borderRight: "1px solid #e0e0e0" },
                    },
                    change == 1
                      ? {
                          title: "Activate/Deactivate",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          width: "2%",
                          render: (rowData) => (
                            <div className="action-column">
                              <button
                                onClick={() => activate(rowData.id)}
                                className="btn btn-success btn-sm"
                              >
                                Activate
                              </button>

                              <button
                                onClick={() => openModal(rowData.id)}
                                className="btn btn-danger btn-sm"
                              >
                                Deactivate
                              </button>
                            </div>
                          ),
                        }
                      : change == 2
                      ? {
                          title: "Deactivate",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          width: "2%",
                          render: (rowData) => (
                            <button
                              onClick={() => openModal(rowData.id)}
                              className="btn btn-danger btn-sm"
                            >
                              Deactivate
                            </button>
                          ),
                        }
                      : {
                          title: "Activate",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          width: "2%",
                          render: (rowData) => (
                            <button
                              onClick={() => activate(rowData.id)}
                              className="btn btn-success btn-sm"
                            >
                              Active
                            </button>
                          ),
                        },

                    {
                      title: "View",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                      width: "400px",
                      render: (rowData) => (
                        <div onClick={() => setColorChange(rowData.id)}>
                          {colorChange == rowData.id ? (
                            <Link
                              style={{ color: "black" }}
                              to={{
                                pathname: `https://d25u15mvjkult8.cloudfront.net/gamesee/${rowData.streamkey}.mp4`,
                              }}
                              target="_blank"
                            >
                              https://d25u15mvjkult8.cloudfront.net/gamesee/
                              {rowData.streamkey}.mp4
                            </Link>
                          ) : (
                            <Link
                              // className={linkClicked && "clickedLink"}
                              to={{
                                pathname: `https://d25u15mvjkult8.cloudfront.net/gamesee/${rowData.streamkey}.mp4`,
                              }}
                              target="_blank"
                            >
                              https://d25u15mvjkult8.cloudfront.net/gamesee/
                              {rowData.streamkey}.mp4
                            </Link>
                          )}
                        </div>
                      ),
                    },
                    {
                      title: "Approve%",
                      field: "aprovedPercentage",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Added Date",
                      render: (rowData) => (
                        <Moment
                          date={rowData.added_date}
                          format="YYYY/MM/DD hh:mm A"
                        />
                      ),
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                      },
                    },
                    {
                      title: "Title",
                      field: "title",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Username",
                      field: "userId",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                  ]}
                  data={data}
                  options={{
                    showTitle: false,
                    paginationPosition: "top",
                    exportButton: true,
                    paging: true,
                    pageSizeOptions: [
                      5,
                      10,
                      20,
                      30,
                      50,
                      70,
                      { value: data.length, label: "All" },
                    ],
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(LivestreamVideoTable);
