import React, { useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import { Bold, Underline, AlignLeft, AlignCenter, AlignRight, Strikethrough, Italic, List, ListOrdered, Heading2 } from 'lucide-react';
import { Button, ButtonGroup, TextField } from '@mui/material';

const MenuBar = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <ButtonGroup variant="contained" color="primary" aria-label="text formatting">
            <Button onClick={() => editor.chain().focus().toggleBold().run()} disabled={!editor.can().chain().focus().toggleBold().run()}>
                <Bold />
            </Button>
            <Button onClick={() => editor.chain().focus().toggleItalic().run()} disabled={!editor.can().chain().focus().toggleItalic().run()}>
                <Italic />
            </Button>
            {/* <Button onClick={() => editor.chain().focus().toggleUnderline().run()} disabled={!editor.can().chain().focus().toggleUnderline().run()}>
                <Underline />
            </Button> */}
            <Button onClick={() => editor.chain().focus().setTextAlign('left').run()} disabled={!editor.can().chain().focus().setTextAlign('left').run()}>
                <AlignLeft />
            </Button>
            <Button onClick={() => editor.chain().focus().setTextAlign('center').run()} disabled={!editor.can().chain().focus().setTextAlign('center').run()}>
                <AlignCenter />
            </Button>
            <Button onClick={() => editor.chain().focus().setTextAlign('right').run()} disabled={!editor.can().chain().focus().setTextAlign('right').run()}>
                <AlignRight />
            </Button>
            <Button onClick={() => editor.chain().focus().toggleOrderedList().run()} disabled={!editor.can().chain().focus().toggleOrderedList().run()}>
                <ListOrdered />
            </Button>
        </ButtonGroup>
    );
};

function TextEditot() {
    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            ListItem,
            BulletList,
            OrderedList,
        ],
        content: '<p>Hello World!</p>',
    });
    const [htmlContent, setHtmlContent] = useState('');

    const handleSubmit = () => {
        if (editor) {
            setHtmlContent(editor.getHTML());
        }
    };
    return (
        <div className='content'>
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
            <Button className='bg-red-500' onClick={handleSubmit} style={{ marginTop: '10px' }}>
                Submit
            </Button>
            <TextField
                label="HTML Output"
                multiline
                rows={10}
                value={htmlContent}
                variant="outlined"
                fullWidth
                style={{ marginTop: '20px' }}
                InputProps={{
                    readOnly: true,
                }}
            />
            <p className='text-orange-700'>fewfbewfbwfbwfbwfbwfhbwfhjbewfhjwbefhjb</p>
        </div>
    )
}

export default TextEditot