import React, { forwardRef, useEffect, useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import "./allblogs.css";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
// import Card from "react-bootstrap/Card";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";

const AllBlogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [isActivated, setIsActivated] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const history = useHistory();
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    // setToastStatus(false);
    getBlogs();
  }, [isActivated, isDeactivated]);

  const getBlogs = async () => {
    setLoading(true);
    const body = { user_id: 1 };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/panel-blog-list",
        body
      );
      if (responce.data.Success == 1) {
        console.log(responce);
        setData(responce.data.data.selectAllBlogs);
        setLoading(false);
      } else {
        console.log("error.......");
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const actionPerformend = async (blogId, action) => {
    const body = { blogID: blogId, status: action };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/new-blog-isactive",
        body
      );
      if (action == 1) {
        if (responce.data.Success == 1) {
          setIsActivated(!isActivated);
          toast.success("Blog has been Activated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("Error in if.....");
        }
      } else {
        if (responce.data.Success == 1) {
          setIsDeactivated(!isDeactivated);
          toast.success("Blog has been Deactivated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("Error in else.....");
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Row>
            <Col>
              <Card className="card">
                <Card.Header className="heading">
                  <div className="inside-header">
                    All Blogs
                    <Link to="/addBlog">
                      <Button
                        className="table-button"
                        variant="primary"
                        size="md"
                      >
                        Add Blog
                      </Button>
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="body">
                  {loading == true ? (
                    <Loader />
                  ) : (
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      columns={[
                        {
                          title: "#",
                          field: "tableData.id",
                          render: (rowData) => rowData.tableData.id + 1,
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Blog ID",
                          field: "blog_id",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Title",
                          field: "blog_title",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Author",
                          field: "author",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Read Time",
                          field: "read_time",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Meta Title",
                          field: "meta_title",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Meta Desc",
                          field: "meta_desc",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Active/Inactive",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                          render: (rowData) => {
                            return rowData.status == 0 ? (
                              <Button
                                className="table-button"
                                variant="success"
                                onClick={() =>
                                  actionPerformend(rowData.blog_id, 1)
                                }
                                size="md"
                              >
                                Active
                              </Button>
                            ) : (
                              <Button
                                className="table-button"
                                variant="danger"
                                onClick={() =>
                                  actionPerformend(rowData.blog_id, 2)
                                }
                                size="md"
                              >
                                Inactive
                              </Button>
                            );
                          },
                        },
                        {
                          title: "Edit",
                          render: (rowData) => (
                            <Link
                              to={{ pathname: "/editBlog", state: rowData }}
                            >
                              <Button
                                className="table-button"
                                variant="primary"
                                size="md"
                              >
                                Edit
                              </Button>
                            </Link>
                          ),
                        },
                      ]}
                      options={{
                        paginationPosition: "top",
                        showTitle: false,
                        exportButton: true,
                        paging: true,
                        pageSizeOptions: [
                          5,
                          20,
                          40,
                          70,
                          { value: data.length, label: "All" },
                        ],
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AllBlogs);
