import React from 'react';
import {Card} from 'react-bootstrap'

const Cards = ({title,text,background,color}) => {
  return <div>
            <Card style={{height:'10rem', padding:'5px 10px', backgroundColor:background, borderRadius:'4px' }}>
            <Card.Body>
            <Card.Title style={{color:color, fontSize:'1rem'}}>{title}</Card.Title>
            <Card.Text style={{fontSize:'1.25rem',color:color}}>
                {text}
            </Card.Text>
            </Card.Body>
            </Card>
         </div>;
};

export default Cards;
