import React, { useState, useEffect } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import Loader from "../Spinner/Spinner";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import axios from "axios";

function EditMetaTags(props) {
  console.log("prps>>>>", props);
  const [allPagesSelect, setAllPagesSelect] = useState([]);
  const [page, setPage] = useState(props?.location?.state?.page_id);
  const [metaTitle, setMetaTitle] = useState(props?.location?.state?.title);
  const [metaDesc, setMetaDesc] = useState(
    props?.location?.state?.meta_description
  );
  const [metaKeywords, setMetakeywords] = useState(
    props?.location?.state?.meta_keywords
  );
  const [ogTitle, setOgitle] = useState(props?.location?.state?.ogtitle);
  const [ogUrl, setOgUrl] = useState(props?.location?.state?.ogurl);
  const [ogDescription, setOgDescription] = useState(
    props?.location?.state?.ogdescription
  );
  const [ogImgurl, setOgImgUrl] = useState(props?.location?.state?.ogimageurl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPages();
  }, []);

  const getAllPages = async () => {
    const response = await instance.get(RequsetData.allWebsitePages);
    if (response?.status === 200) {
      console.log("response: ", response)
      setAllPagesSelect(response?.data?.data);
    }
  }

  const editProfile = async () => {
    setLoading(true);
    const body = {
      page_id: page,
      title: metaTitle,
      meta_description: metaDesc,
      meta_keywords: metaKeywords,
      ogtitle: ogTitle,
      ogurl: ogUrl,
      ogimageurl: ogImgurl,
      ogdescription: ogDescription,
    };
    const responce = await axios.post(
      "https://api.gamesee.tv/v2/metadescriptionspostupdate",
      body
    );
    if (responce?.data?.Success === 1) {
      setLoading(false);
      toast.success(responce?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.warn(responce?.data?.error?.error_message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const submit = () => {
    editProfile();
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Edit Meta Tags</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Select Page <span className="mandatory">*</span>
                    </Form.Label>
                    <Form.Select
                      disabled
                      value={page}
                      onChange={(e) => setPage(e.target.value)}
                    >
                      {console.log("all", allPagesSelect)}
                      {allPagesSelect?.map((pageInfo) => (
                        <option key={pageInfo?.page_id} value={pageInfo?.page_id}>{pageInfo?.page_name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.title}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.meta_description}
                      as="textarea"
                      rows={4}
                      onChange={(e) => setMetaDesc(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Keywords</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.meta_keywords}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Title</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.ogtitle}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Url</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.ogurl}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgUrl(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Description</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.ogdescription}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>OG Image Url</Form.Label>
                    <Form.Control
                      defaultValue={props?.location?.state?.ogimageurl}
                      as="textarea"
                      rows={2}
                      onChange={(e) => setOgImgUrl(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              {loading == true ? (
                <Loader />
              ) : (
                <Button
                  className="submit-btn"
                  onClick={() => submit()}
                  variant="primary"
                >
                  Submit
                </Button>
              )}
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default EditMetaTags;
