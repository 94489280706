//Under Second Content tab Video Comment Reports
import React, { forwardRef, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./videocommentReport.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Spinner/Spinner";
import axios from "axios";

const VideocommentReports = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [colorChange, setColorChange] = useState("");
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getVideos();
    // setLoader(true);
    // axios
    //   .post("https://api.gamesee.tv/v2/comment-report-sheet", { user_id: 1 })
    //   .then((res) => {
    //     if (res.data.Success == 1) {
    //       console.log(res);
    //       setData(res.data.data.commentReport);
    //       setLoader(false);
    //     }
    //   });
  }, [removed]);

  const getVideos = async () => {
    setLoader(true);
    const body = { user_id: 1 };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/comment-report-sheet",
        body
      );
      console.log(responce);
      setData(responce.data.data.commentReport);
      setLoader(false);
    } catch (err) {
      console.log("Error....", err);
    }
  };
  const remove = (id) => {
    setLoader(true);
    const body = {
      videoId: id,
    };
    try {
      const responce = axios.post(
        "https://api.gamesee.tv/v2/panel-video-comment-action",
        body
      );
      if (responce.data.Success == 1) {
        setRemoved(!removed);
        toast.success("Video Comment was Removed Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log(responce.data.error.error_message);
      }
    } catch (err) {
      console.log("Error....", err);
    }
    axios
      .post("https://api.gamesee.tv/v2/panel-video-comment-action", { body })
      .then((res) => {
        if (res.data.Success == 1) {
          setLoader(false);
          toast.success("Video Comment was Removed Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.reload();
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">
              Video Comment Report Table
            </Card.Header>
            <Card.Body>
              {loader == true ? (
                <Loader />
              ) : (
                <MaterialTable
                  icons={tableIcons}
                  title={`Video Comments`}
                  columns={[
                    {
                      title: "#",
                      field: "tableData.id",
                      render: (rowData) => rowData.tableData.id + 1,
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Video Id",
                      field: "id",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Username",
                      field: "ownerusername",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Video Link",
                      field: "url",
                      render: (rowData) => (
                        console.log("rowdata.....", rowData),
                        (
                          <div
                            onClick={() => setColorChange(rowData.tableData.id)}
                          >
                            {colorChange == rowData.tableData.id ? (
                              <Link
                                style={{ color: "black" }}
                                to={{
                                  pathname: `https://d25u15mvjkult8.cloudfront.net/gamesee/${rowData.video_code}`,
                                }}
                                target="_blank"
                              >
                                https://d25u15mvjkult8.cloudfront.net/ gamesee/
                                {rowData.video_code}
                              </Link>
                            ) : (
                              <Link
                                to={{
                                  pathname: `https://d25u15mvjkult8.cloudfront.net/gamesee/${rowData.video_code}`,
                                }}
                                target="_blank"
                              >
                                https://d25u15mvjkult8.cloudfront.net/ gamesee/
                                {rowData.video_code}
                              </Link>
                            )}
                          </div>
                        )
                      ),
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Type",
                      field: "type",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Reported By",
                      field: "user_name",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                      },
                      width: "2%",
                    },
                    {
                      title: "Report Reason",
                      field: "report_reason",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Comment",
                      field: "comment",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Added Date",
                      render: (rowData) => rowData.added_date.slice(0, 10),
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        borderLeft: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Action",
                      render: (rowData) => (
                        <Button
                          onClick={() => remove(rowData.id)}
                          className="table-button"
                          variant="danger"
                        >
                          Remove
                        </Button>
                      ),
                    },
                  ]}
                  data={data}
                  options={{
                    showTitle: false,
                    paginationPosition: "top",
                    exportButton: true,
                    paging: true,
                    pageSizeOptions: [
                      10,
                      20,
                      30,
                      40,
                      70,
                      { value: data.length, label: "All" },
                    ],
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(VideocommentReports);
