import React,{useState,forwardRef, useEffect} from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Sidebar from '../../Sidebar/MainSidebar/Sidebar';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './topstreamersreport.css';
import axios from 'axios';

const TopStreamersReport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState([]);
    const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  return (
    <>
    <Sidebar />
    <div className='content'>
        <Container>
             <Card>
                    <Card.Header className='heading'>Live Stream reports ( Streamer Wise)</Card.Header>
                <Card.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group>  
                            <Form.Label>Select Date</Form.Label>
                                <InputGroup size="md" className="mb-3">
                                {/* <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" /> */}
                            <Form.Control aria-describedby="basic-addon1" type="date" aria-describedby="inputGroup-sizing-sm" placeholder="Enter Email..." onChange={e=> setStartDate(e.target.value)} />
                            <InputGroup.Text id="inputGroup-sizing-sm">To</InputGroup.Text>
                            <Form.Control aria-describedby="basic-addon1" type="date" aria-describedby="inputGroup-sizing-sm" placeholder="Enter Email..." onChange={e=> setEndDate(e.target.value)} />
                    </InputGroup>
                    </Form.Group>
                </Form>           
                </Col>
                </Row>
                <Button className='table-button' varient='primary'>Search</Button>
                <div className='streamer-table'>
                <MaterialTable
                      data={data}
                      icons={tableIcons}
                      title={'All Users'}
                      columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                          {title:'ID',field:'id'},
                          {title:'User Profile',field:'User_Profile'},
                          {title:'Activated Streams Duration',field:'Activated_Streams_Duration'},
                          {title:'Deactivated Stream Duration',field:'Deactivated_Stream_Duration'},
                          {title:'Total Durations',field:'Total_Durations'},
                          {title:'No. of Views',field:'No._of_Views'},
                        ]}
          
                      options={{
                        exportButton:true,
                        paging:true,
                        // pageSize: rowsPerPage,
                        pageSizeOptions: [5, 10, 15 , { value: data.length, label: 'All' }],
                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                      }}   
                    />
                    </div> 
                </Card.Body>
                </Card>
        </Container>
    </div>
    </>
  )
};

export default TopStreamersReport;
