import React, { useEffect, useState } from "react";
import "./executiveReport.css";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import axios from "axios";
import "react-dates/lib/css/_datepicker.css";

const ExecutiveReport = () => {
  const [usersData, setUsersData] = useState([]);
  const [userId, setUserId] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [liveVideodata, setLiveVideoData] = useState([]);
  const [uploadVideodata, setUploadVideoData] = useState([]);

  useEffect(() => {
    axios
      .post("https://api.gamesee.tv/v2/exe-report-user-list", { user_id: 1 })
      .then((res) => {
        if (res.data.Success == 1) {
          console.log(res.data.data.executiveUserList);
          setUsersData(res.data.data.executiveUserList);
        }
      });
  }, []);

  const getData = () => {
    axios
      .post("https://api.gamesee.tv/v2/exe-report-particular-user", {
        user_id: parseInt(userId),
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (res.data.Success == 1) {
          console.log("user report.....", res.data.data);
          setLiveVideoData(res.data.data.liveVideoExecutive);
          setUploadVideoData(res.data.data.uploadVideoExecuton);
        } else {
          // console.log(res);
        }
      });
    // console.log(startDate + ' ' + endDate + ' ' + userId)
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Row className="form-row">
            <h3 className="page-heading">Executive Reports</h3>
            <Col className="cols" md={3} sm={6} xs={12} lg={true}>
              <Form.Label>Start Date</Form.Label>
              {/* <DateRangePicker
                                  startDate={startDate}
                                  startDateId="s_id"
                                  endDate={endDate}
                                  endDateId="e_id"
                                  onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate); }}
                                  focusedInput={focusedInput}
                                  onFocusChange={e => setFocusedInput(e)}
                                  displayFormat="DD/MM/YYYY" /> */}
              <Form.Group>
                <Form.Control
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="cols" md={3} sm={5} xs={12} lg={true}>
              <Form.Label>End Date</Form.Label>
              <Form.Group>
                <Form.Control
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="cols" md={6} sm={5} xs={12} lg={true}>
              <Form.Label>Users</Form.Label>
              <Form.Group>
                <Form.Select
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                >
                  {usersData.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.username}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className="cols" lg={true}>
              <Form.Label className="mt-3"></Form.Label>
              <Form.Group>
                <Button onClick={() => getData()} className="submit-button">
                  Submit
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <h3 className="chart-heading">
            Total (Activated + Deactivated) Live Videos Report
          </h3>
          <ResponsiveContainer height={500} width="100%" aspect={4}>
            <LineChart
              width={500}
              height={300}
              data={liveVideodata}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          <h3 className="chart-heading">
            Total (Activated + Deactivated) Uploaded Videos Report
          </h3>
          <ResponsiveContainer height={500} width="100%" aspect={4}>
            <LineChart
              width={500}
              height={300}
              data={uploadVideodata}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Container>
      </div>
    </>
  );
};

export default ExecutiveReport;
