import axios from "axios";

export const instance = axios.create({
  baseURL: "https://api.gamesee.tv/",
  // baseURL: "http://localhost:3000/",
  timeout: 5000,
  headers: {
    Authorization:
      "cb70755a985f963d64ab1bb0541b0980e0edf54931f00c9f7760d17477a90d94",
  },
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    // console.log(response);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
