import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AbortStream = () => {
  const [streamKey, setStreamKey] = useState("");
  const [showModal, setShowModal] = useState(false);

  const abort = () => {
    axios
      .post("https://5e2fcd04207aa.streamlock.net:8086/disconnect", null, {
        params: { sname: streamKey },
      })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setStreamKey("");
          setShowModal(false);
          toast.success("Livestream has been Aborted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warn("There is some poroblem in the api!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const deactivate = () => {
    setShowModal(true);
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Abort Stream</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to Abort the LiveStream..?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                variant="secondary"
                size="md"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="button"
                variant="danger"
                size="md"
                onClick={() => abort()}
              >
                Deactivate
              </Button>
            </Modal.Footer>
          </Modal>
          <Card>
            <Card.Header className="heading">
              Abort Ongoing Livestream{" "}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter StreamKey</Form.Label>
                    <Form.Control
                      value={streamKey}
                      type="text"
                      onChange={(e) => setStreamKey(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Form.Group className="mb-3">
                  <Button
                    onClick={() => deactivate()}
                    className="table-button"
                    variant="danger"
                  >
                    Abort Stream
                  </Button>
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AbortStream;
