import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { DatePicker, Space } from "antd";
import { Select } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import moment from "moment";
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const Editblog = (props) => {
  const myRelated = props.location.state.related_blogs.split(",");
  const final = myRelated.map((item) => {
    return parseInt(item);
  });
  console.log(final);
  const [blogId, setBlogId] = useState(props.location.state.blog_id);
  const [blogTitle, setBlogTitle] = useState(props.location.state.blog_title);
  const [blogImage, setBlogImage] = useState("");
  const [choseblogImage, setchoseBlogImage] = useState();
  const [blogAuthor, setBlogAuthor] = useState(props.location.state.author);
  const [readTime, setReadTime] = useState(props.location.state.read_time);
  const [publishDate, setPublishDate] = useState(
    props.location.state.publish_date
  );
  const [publishDateShow, setPublishDateShow] = useState(
    moment(props.location.state.publish_date).format("YYYY-MM-DD")
  );
  const [metaTitle, setMetaTitle] = useState(props.location.state.meta_title);
  const [metaDesc, setMetaDesc] = useState(props.location.state.meta_desc);

  const [blogDesc, setBlogDesc] = useState(props.location.state.blog_desc);
  const [checkedItems, setCheckedItems] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  console.log(props.location.state.blog_image);
  useEffect(() => {
    axios.post("https://api.gamesee.tv/v2/related-blogs-list").then((res) => {
      if (res.data.Success === 1) {
        setRelatedBlogs(res.data.data.relatedBlogList);
      }
    });
  }, []);

  const handleCheckbox = (event) => {
    var updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };

  const handleChange = (value) => {
    setCheckedItems(value);
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Add New Blog</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Blog Title</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Blog Title"
                      value={blogTitle}
                      onChange={(e) => setBlogTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Blog Image</Form.Label>
                    <Row>
                      <Col xl={2} md={5}>
                        <img
                          src={props.location.state.blog_image}
                          width={100}
                        />
                      </Col>
                      <Col xl={10} md={7}>
                        <Form.Control
                          type="file"
                          onChange={(e) => setBlogImage(e.target.files[0])}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Author Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={blogAuthor}
                      placeholder="Author Name"
                      onChange={(e) => setBlogAuthor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Read Time</Form.Label>
                    <Form.Control
                      type="text"
                      value={readTime}
                      placeholder="Read Time"
                      onChange={(e) => setReadTime(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={metaTitle}
                      placeholder="Meta Title"
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      defaultValue={metaDesc}
                      as="textarea"
                      rows={3}
                      placeholder="Meta Desc"
                      onChange={(e) => setMetaDesc(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ display: "block" }}>
                      Publish Date
                    </Form.Label>
                    <DatePicker
                      defaultValue={moment(publishDate)}
                      style={{ width: "100%", marginBottom: "16px" }}
                      format={dateFormat}
                      onChange={(e) =>
                        setPublishDate(moment(e._d).format(dateFormat))
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Related Blogs</Form.Label>
                    <Select
                      defaultValue={
                        props.location.state.related_blogs.length > 0
                          ? final
                          : []
                      }
                      mode="multiple"
                      style={{
                        width: "100%",
                        lineHeight: "2.5715",
                      }}
                      placeholder="Select Related Blogs"
                      onChange={handleChange}
                      optionLabelProp="label"
                    >
                      {relatedBlogs.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item.blog_id}
                            label={item.blog_title}
                          >
                            <div className="demo-option-label-item">
                              {item.blog_title}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Link
                to={{
                  pathname: "/editBlog_Desc",
                  state: {
                    blogId: blogId,
                    blogImage: blogImage,
                    author: blogAuthor,
                    readTime: readTime,
                    publishDate: publishDate,
                    relatedBlog: checkedItems,
                    blogDesc: blogDesc,
                    meta_tile: metaTitle,
                    meta_desc: metaDesc,
                  },
                }}
              >
                <Button className="submit-btn" variant="primary">
                  Next
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Editblog);
