import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Toast from "../../reusable/Toast/Toast";
import moment from "moment";
import { Button } from "antd";

function UserCoinEarn() {
    const [res, setRes] = useState([]);
    const [date, setDate] = useState({month:null,year:null});
    const [isDateSelected, setIsDateSelected] = useState(false)

    const fetchData = async () => {
        const response = await instance.post(
            RequsetData.userCoinEarnDayWise,{month:date.month, year:date.year}
        );
        if (response?.status === 200) {
            const data = response?.data?.data?.map(d => ({ ...d, transaction_day: moment(d.transaction_day).format("DD/MM/YYYY") }))
            setRes(data);
            return data;
        }
    };

    useEffect(() => { fetchData(); }, []);

    // const { data, status } = useQuery(["GamesReport"], fetchData);

    const { status } = useQuery(["GamesReport", date], fetchData, { enabled: isDateSelected });

    const handleDateChange = (month, year) => {
        setDate({ month: month, year: year });
        if (month && year) {
            setIsDateSelected(true);
        }
        else {
            setIsDateSelected(false);
        }
    };

    // console.log("data<<>>", data);

    return (
        <div className="content">
            <Toast />
            {status === "loading" ? (
                <p style={{ textAlign: 'center' }}>Loading...</p>
            ) : (
                <MatTable
                    heading={"Mini-Games Coins Report"}
                    data={res}
                    date={date} 
                    onDateChange={handleDateChange}
                    cols={[
                        {
                            title: "#",
                            field: "tableData.id",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "User Id",
                            field: "user_id",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Device Id",
                            field: "device_id",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Added Coins",
                            field: "added_coins",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Used Coins",
                            field: "removed_coins",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Date",
                            field: "transaction_day",
                            render: (rowData) => rowData.transaction_day,
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Actions",
                            field: "added_date",
                            render: (rowData) =>
                                <>
                                    <Link to={{ pathname: "/single-user-coin-earn", state: rowData }}>
                                        <Button type="primary">View</Button>
                                    </Link>
                                </>,
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                    ]}
                />
            )}
        </div>
    )
}

export default UserCoinEarn