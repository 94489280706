import React, { useEffect, useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Toast from "../../reusable/Toast/Toast";
import { Button } from "antd";

function ZeroSearchReport() {
    const [res, setRes] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    useEffect(() => {
        fetchData();
    }, [])

    const filterResult = () => {
        fetchData();
    }

    const fetchData = async () => {
        const body = {
            "startDate": startDate,
            "endDate": endDate,
        }
        const response = await instance.post(
            RequsetData.zeroSearchResults, body
        );
        if (response?.status === 200) {
            setRes(response?.data?.data);
            return response;
        }
    };

    const columns = [
        {
            title: "#",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Search Text",
            field: "search_query",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Times Searched",
            field: "total_results",
            render: (rowData) => <Button type="primary">{rowData?.total_results}</Button>,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
    ]
    return (
        <div className="content">
            <Toast />
            <Container>
                <Row className="form-row">
                    <Col className="cols" md={6} sm={6} xs={12} lg={5}>
                        <Form.Group>
                            <Form.Control
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="cols" md={6} sm={6} xs={12} lg={5}>
                        <Form.Group>
                            <Form.Control
                                type="date"
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col className="cols" lg={2}>
                        <Form.Group>
                            <Button className="submit-button" onClick={filterResult}>
                                Submit
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <MatTable
                heading={"Mini-Games Zero Search Results"}
                data={res}
                cols={columns}
            />
        </div>
    )
}

export default ZeroSearchReport