import React, { useEffect, useState } from "react";
import "./login.css";
import { Route, Redirect, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { setUserSession } from "../../Utils/Common/Common";
import Logo from "../../Assets/logo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getToken } from "../../Utils/Common/Common";

const Login = ({ props }) => {
  const history = useHistory();
  const [nameValue, setNameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("userId"));
  useEffect(() => {
    const islogin = getToken();
    console.log("isLogin", islogin);
    if (islogin == "true") {
      history.push("/dashboard");
    }
  }, []);
  const loginAction = () => {
    axios
      .post("https://api.gamesee.tv/v2/panel-login", {
        userName: nameValue,
        password: passwordValue,
      })
      .then((res) => {
        console.log(res);
        if (res.data.Success === 1) {
          let userData = res.data.data.loginUser[0].username;
          let userId = res.data.data.loginUser[0].id;
          if (userData) {
            setUserSession();
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("username", userData);
            history.push("/dashboard");
          }
        } else {
          setErrorMessage(res.data.error.error_message);
          setAlert(true);
        }
      });
  };
  // else{
  //   setAlert(!alert);
  //   setErrorMessage(res.data.error.error_message);
  //       }
  return (
    <>
      {alert && (
        <Alert
          className="error"
          severity="error"
          onClose={() => {
            setAlert(false);
          }}
        >{`${errorMessage}.  Please Try Again!!`}</Alert>
      )}
      <Container>
        <Row>
          <Col className="main">
            <div className="login-form">
              <div className="logo-container">
                <img className="logo" src={Logo} />
              </div>
              <div className="form">
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setNameValue(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPasswordValue(e.target.value)}
                  />
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button
                    className="button"
                    onClick={() => loginAction()}
                    variant="primary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
