import React from 'react'

function EditminiGames(props) {
    console.log("props>>", props)
    return (
        <div className='content'>
            <div>
                Hellooo
            </div>
        </div>
    )
}

export default EditminiGames