import React, { forwardRef, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import "./allfaq.css";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from "../../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllFaq = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/panel-faqs-list", {
        user_id: 1,
      })
      .then((res) => {
        if (res.data.Success == 1) {
          setData(res.data.data.selectAllFaqs);
          console.log(res.data.data);
          setLoader(false);
        } else {
          console.log(res.error.error_code);
        }
      });
  }, [deleted]);
  const deleteFunction = (faqId) => {
    axios
      .post("https://api.gamesee.tv/v2/panel-delete-faq", {
        faqId: faqId,
      })
      .then((res) => {
        if (res.data.Success == 1) {
          setDeleted(!deleted);
          toast.warn("FAQ deleted successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
        }
      });
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Row>
            <Col>
              <Card className="card">
                <Card.Header className="heading">
                  <div className="inside-header">
                    All FAQ's
                    <Link to="/addFAQ">
                      <Button
                        className="table-button"
                        variant="primary"
                        size="md"
                      >
                        Add FAQ
                      </Button>
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="body">
                  {loader == true ? (
                    <Loader />
                  ) : (
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      columns={[
                        {
                          title: "#",
                          field: "tableData.id",
                          render: (rowData) => rowData.tableData.id + 1,
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                        {
                          title: "FAQ ID",
                          field: "id",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Title",
                          field: "title",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Category",
                          field: "category",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Language",
                          field: "lang",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Inactive",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                          render: (rowData) => (
                            <Button
                              className="table-button"
                              variant="danger"
                              onClick={() => deleteFunction(rowData.id)}
                            >
                              Delete
                            </Button>
                          ),
                        },
                        {
                          title: "Edit",
                          render: (rowData) => (
                            <Link to={{ pathname: "/editFaq", state: rowData }}>
                              <Button
                                className="table-button"
                                variant="primary"
                              >
                                Edit
                              </Button>
                            </Link>
                          ),
                        },
                      ]}
                      // actions={[
                      //   {
                      //     icon: () => (
                      //       <Select
                      //         labelId="demo-simple-select-label"
                      //         id="demo-simple-select"
                      //         style={{ width: 100 }}
                      //         value={category}
                      //         onChange={(e) => setCategory(e.target.value)}
                      //       >
                      //         <MenuItem value={"all"}>
                      //           <em>All</em>
                      //         </MenuItem>
                      //         <MenuItem value={"General"}>General</MenuItem>
                      //         <MenuItem value={"Android"}>Android</MenuItem>
                      //         <MenuItem value={"Web"}>Web</MenuItem>
                      //       </Select>
                      //     ),
                      //     //   tooltip: "Filter Year",
                      //     isFreeAction: true,
                      //   },
                      // ]}
                      options={{
                        // hideFilterIcons: true,
                        filtering: filter,
                        paginationPosition: "top",
                        showTitle: false,
                        exportButton: true,
                        paging: true,
                        pageSizeOptions: [
                          5,
                          30,
                          40,
                          70,
                          { value: data.length, label: "All" },
                        ],
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AllFaq);
