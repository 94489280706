import React, { useState } from 'react';
import Sidebar from '../Sidebar/MainSidebar/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import axios from 'axios';

const AddEvent = () => {
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventTime, setEventTime] = useState('');
    const [eventType, setEventType] = useState('');
    const [playerType, setPlayerType] = useState('');
    const [totalParticipants, setTotalParticipants] = useState('');
    const [winnerPrice, setWinnerPrice] = useState('');
    const [cheerPrice, setCheerPrice] =  useState('');
    const [playRules, setPlayRules] = useState('');
    const [map,setMap] = useState('');
    const [mode, setMode] = useState('');

    // const reset = () =>{
    //     document.getElementById('myform').reset();
    // }

  return (
        <>
            {/* <Sidebar /> */}
            <div className='content'>
            <Container>
                <Card>
                    <Card.Header className='heading'>Add Event</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                            <Form id='myform'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event Name</Form.Label>
                                    <Form.Control type="text" placeholder="Event Name" onChange={e=> setEventName(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event Date</Form.Label>
                                    <Form.Control type="date" onChange={e=> setEventDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event Time</Form.Label>
                                    <Form.Control type="time" onChange={e=> setEventTime(e.target.value)} />
                                </Form.Group>       
                                <Form.Group className="mb-3">
                                    <Form.Label>Event Type</Form.Label>
                                    <Form.Select value={eventType} onChange={e => setEventType(e.target.value)}>
                                        <option value="Winner Based">Winner Based</option>
                                        <option value="Kill Based">Kill Based</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Player Type</Form.Label>
                                    <Form.Select value={playerType} onChange={e => setPlayerType(e.target.value)}>
                                        <option value="Solo">Solo</option>
                                        <option value="Duo">Duo</option>
                                        <option value="Squad">Squad</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Total Participants</Form.Label>
                                    <Form.Control type="number" placeholder='Total Participants' onChange={e=> setTotalParticipants(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Winner Price</Form.Label>
                                    <Form.Control type="number" placeholder='Winner Price' onChange={e=> setWinnerPrice(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cheer Price</Form.Label>
                                    <Form.Control type="number" placeholder='Cheer Price' onChange={e=> setCheerPrice(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Play Rules</Form.Label>
                                    <Form.Control type="text" placeholder='Play Rules Link' onChange={e=> setPlayRules(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Map</Form.Label>
                                    <Form.Control type="text" placeholder='Map' onChange={e=> setMap(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mode</Form.Label>
                                    <Form.Control type="text" placeholder='Mode' onChange={e=> setMode(e.target.value)} />
                                </Form.Group>
                            </Form>    
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Button className='table-button' variant='primary'>Submit</Button>
                        <Button className='table-button' variant='danger'>Reset</Button>
                    </Card.Footer>
                </Card>
            </Container>                
            </div>
        </>
    )
};

export default AddEvent;
