import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { getToken } from "../../Utils/Common/Common";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Cards from "../Cards/Cards";
import CountUp from "react-countup";
import axios from "axios";
import "./dashboard.css";

const Dashboard = () => {
  const [liveVideos, setLiveVideos] = useState([]);
  const [uploadedVideos, setuploadedVideos] = useState([]);

  useEffect(() => {
    console.log("hello");
    videoData();
    // axios.get('https://api.gamesee.tv/v2/all-videos-details').then((res)=>{
    //   console.log(res);
    //   setLiveVideos(res.data.data.allliveVideos[0]);
    //   setuploadedVideos(res.data.data.alluploadVideo[0]);
    // })
  }, []);

  const videoData = async () => {
    try {
      const responce = await axios.get(
        "https://api.gamesee.tv/v2/all-videos-details"
      );
      setLiveVideos(responce.data.data.allliveVideos[0]);
      setuploadedVideos(responce.data.data.alluploadVideo[0]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {/* <Sidebar /> */}
      <Container>
        <div className="content">
          <div className="data">
            <h3 className="row-heading">Uploaded Videos</h3>
            <Row className="row">
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#009efa"
                  title={<CountUp duration={3} end={uploadedVideos.total} />}
                  text="Total Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#00c4ce"
                  title={<CountUp duration={3} end={uploadedVideos.active} />}
                  text="Active Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#f86c6b"
                  title={<CountUp duration={3} end={uploadedVideos.pending} />}
                  text="Pending Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#ffa500"
                  title={<CountUp duration={3} end={uploadedVideos.deactive} />}
                  text="Deactive Videos"
                />
              </Col>
            </Row>
            <h3 className="row-heading">Live Videos</h3>
            <Row className="row" style={{ marginBottom: "20px" }}>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#009efa"
                  title={<CountUp duration={3} end={liveVideos.total} />}
                  text="Total Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#00c4ce"
                  title={<CountUp duration={3} end={liveVideos.active} />}
                  text="Active Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#f86c6b"
                  title={<CountUp duration={3} end={liveVideos.pending} />}
                  text="Pending Videos"
                />
              </Col>
              <Col className="cloumns" md={true}>
                <Cards
                  color="white"
                  background="#ffa500"
                  title={<CountUp duration={3} end={liveVideos.deactive} />}
                  text="Deactive Videos"
                />
              </Col>
            </Row>
            {localStorage.setItem("upload-total", uploadedVideos.total)}
            {localStorage.setItem("upload-active", uploadedVideos.active)}
            {localStorage.setItem("upload-pending", uploadedVideos.pending)}
            {localStorage.setItem("upload-deactive", uploadedVideos.deactive)}

            {localStorage.setItem("live-total", liveVideos.total)}
            {localStorage.setItem("live-active", liveVideos.active)}
            {localStorage.setItem("live-deactive", liveVideos.deactive)}
            {localStorage.setItem("live-pending", liveVideos.pending)}
          </div>
        </div>
      </Container>
    </>
  );
};

export default withRouter(Dashboard);
