import React, { useState, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import "./addmanagegamers.css";
import { FormGroup } from "react-bootstrap";

const AddManageGamers = () => {
  const [userMail, setUserMail] = useState("");
  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [officialStreamerStatus, setOfficialStreamerStatus] = useState(false);
  const [otherStatus, setOtherStatus] = useState(false);

  const [paidStreamer, setPaidStreamer] = useState(false);
  const [unPaidStreamer, setUnPaidStreamer] = useState(false);

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const changeStatus = (abc) => {
    // abc(true);
    setOfficialStreamerStatus(false);
    setOtherStatus(false);
    setUnPaidStreamer(false);
    setPaidStreamer(false);
    // abc(true);
    // console.log("himi...",abc);
    console.log(officialStreamerStatus);
  };

  const search = () => {
    if (!userMail) {
      setErrorText("User Mail is Required!!");
      setShowError(true);
    } else {
      axios
        .post("https://api.gamesee.tv/v2/gamers-status", {
          user_email: userMail,
        })
        .then((res) => {
          if (res.data.Success == 1) {
            setShowTable(true);
            setShowError(false);
            setData(res.data.data.gamerManage);
            console.log(res.data.data.gamerManage[0]);
          } else {
            setShowError(true);
            setErrorText(
              "User Mail doesn't exsist or there is something wrong with the API"
            );
          }
        });
    }
  };

  const submit = () => {
    console.log("official ", officialStreamerStatus, "other ", otherStatus);
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          {/* <Form.Check name="group1" type="radio" onClick={()=>changeStatus(setOfficialStreamerStatus)} label={'Official Gamer'}  />
                <Form.Check name="group1" type="radio" onChange={(e)=>changeStatus(setOtherStatus)} label={'Other Gamer'} />
                    <Button onClick={()=>submit()}>Submit</Button> */}

          {showError ? (
            <Alert
              variant="danger"
              onClose={() => setShowError(false)}
              dismissible
            >
              <Alert.Heading>{errorText}</Alert.Heading>
            </Alert>
          ) : null}
          <Card>
            <Card.Header className="heading">Add New Games</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Users Data</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="User's Data"
                      required={true}
                      onChange={(e) => setUserMail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Button
                className="submit-btn"
                onClick={() => search()}
                variant="primary"
              >
                Submit
              </Button>
              <Button className="cancel-btn" variant="danger">
                Cancel
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Header className="heading">Gamer Recodrs</Card.Header>
            <Card.Body>
              {showTable && (
                <MaterialTable
                  data={data}
                  columns={[
                    {
                      title: "Full Name",
                      field: "new_userId",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "UserName",
                      field: "user_name",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Email",
                      field: "user_email",
                      cellStyle: {
                        borderRight: "1px solid #e0e0e0",
                        wordBreak: "break-all",
                      },
                    },
                    {
                      title: "Mark As Gamer/Remove As Gamer",
                      render: (rowData) => (
                        <>
                          <Form.Group>
                            <div className="radio-btns">
                              {/* {console.log(rowData.official_streamer_status)} */}
                              {rowData.official_streamer_status == 1 ? (
                                <>
                                  <Form.Control
                                    name="group1"
                                    type="radio"
                                    label={"Official Gamer"}
                                    value={rowData.official_streamer_status}
                                    defaultChecked
                                  />
                                  <Form.Control
                                    name="group1"
                                    type="radio"
                                    label={"Other Gamer"}
                                  />{" "}
                                </>
                              ) : (
                                <>
                                  <Form.Control
                                    name="group1"
                                    type="radio"
                                    label={"Official Gamer"}
                                    value={rowData.official_streamer_status}
                                  />
                                  <Form.Control
                                    name="group1"
                                    type="radio"
                                    label={"Other Gamer"}
                                    defaultChecked
                                  />
                                </>
                              )}
                              {rowData.user_type == 0 ? (
                                <>
                                  <Form.Control
                                    name="group2"
                                    type="radio"
                                    label={"Paid Streamer"}
                                  />
                                  <Form.Control
                                    name="group2"
                                    type="radio"
                                    label={"Unpaid Streamer"}
                                    defaultChecked
                                  />
                                </>
                              ) : (
                                <>
                                  <Form.Control
                                    name="group2"
                                    type="radio"
                                    label={"Paid Streamer"}
                                    defaultChecked
                                  />
                                  <Form.Control
                                    name="group2"
                                    type="radio"
                                    label={"Unpaid Streamer"}
                                  />
                                </>
                              )}
                            </div>
                          </Form.Group>
                          <Button
                            style={{ marginTop: 20 }}
                            className="btn-sm"
                            variant="primary"
                            onClick={() => submit()}
                          >
                            Submit
                          </Button>
                        </>
                      ),
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    tableLayout: "fixed",
                    exportButton: true,
                    paging: true,
                    pageSizeOptions: [5, 10],
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AddManageGamers);
