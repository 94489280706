import React, { useEffect, useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Toast from "../../reusable/Toast/Toast";
import { Container, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function RecomendedMiniGames() {
    const [gameData, setGameData] = useState([]);
    const [recomendedGames, setRecomendedGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState("");
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        const response = await instance.post(RequsetData?.allMiniGames);
        if (response.data.Success === 1) {
            setGameData(response?.data?.data)
            return response.data;
        }
    };
    const getRecomendedGames = async (data) => {
        const game_id = parseInt(data.target.value);
        setSelectedGame(game_id)
        const response = await instance.get(`${RequsetData?.recomendedGames}?game_id=${game_id}`);
        if (response.data.Success === 1) {
            setRecomendedGames(response?.data?.data)
            return response.data;
        }
    }

    const colums = [
        {
            title: "#",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Game Name",
            field: "game_name",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Game Icon",
            field: "game_icon",
            render: (rowData) => <img src={rowData?.game_icon} width={40}></img>,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Play Count",
            field: "count",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        }
    ]
    return (
        <div className='content'>
            <Container>
                <Card>
                    <Card.Header className="heading">Recomended Games</Card.Header>
                    <Card.Body>
                        <Row>

                            <Form.Group className="mb-3">
                                <Form.Label>Select Game</Form.Label>
                                <Form.Select
                                    value={selectedGame}
                                    onChange={getRecomendedGames}
                                >
                                    <option>Select Game</option>
                                    {gameData.map((games, index) => {
                                        return (
                                            <option key={index} value={games.game_id}>
                                                {games.game_name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>

                        </Row>
                    </Card.Body>
                    <Card.Footer className="footer"></Card.Footer>
                </Card>
            </Container>
            <MatTable
                heading={"All Mini Games"}
                search={true}
                data={recomendedGames}
                cols={colums}
            />
        </div>
    )
}

export default RecomendedMiniGames