import React, { useState, useEffect } from "react";
import "./addnewblog.css";
import { withRouter, Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import axios from "axios";
import { Select } from "antd";
const { Option } = Select;

const AddNewBlog = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogImage, setBlogImage] = useState();
  const [blogAuthor, setBlogAuthor] = useState("");
  const [readTime, setReadTime] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  useEffect(() => {
    axios.post("https://api.gamesee.tv/v2/related-blogs-list").then((res) => {
      if (res.data.Success === 1) {
        console.log("relatedBlogList......", res.data.data.relatedBlogList);
        setRelatedBlogs(res.data.data.relatedBlogList);
      }
    });
  }, []);

  const handleChange = (value) => {
    setCheckedItems(value);
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Add New Blog</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Blog Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Blog Title"
                      onChange={(e) => setBlogTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Blog Image</Form.Label>
                    <Col xl={12} md={12}>
                      <Form.Control
                        type="file"
                        onChange={(e) => setBlogImage(e.target.files[0])}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Author Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Author Name"
                      onChange={(e) => setBlogAuthor(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Read Time</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Read Time"
                      onChange={(e) => setReadTime(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Desc</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Meta Desc"
                      onChange={(e) => setMetaDesc(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Publish Date</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setPublishDate(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Related Blogs</Form.Label>
                    <Select
                      // defaultValue={val}
                      mode="multiple"
                      style={{
                        width: "100%",
                        lineHeight: "2.5715",
                      }}
                      placeholder="Select Related Blogs"
                      onChange={handleChange}
                      optionLabelProp="label"
                    >
                      {relatedBlogs.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item.blog_id}
                            label={item.blog_title}
                          >
                            <div className="demo-option-label-item">
                              {item.blog_title}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Link
                to={{
                  pathname: "/blog_Desc",
                  state: {
                    blogTitle: blogTitle,
                    blogImage: blogImage,
                    author: blogAuthor,
                    readTime: readTime,
                    publishDate: publishDate,
                    relatedBlog: checkedItems,
                    meta_title: metaTitle,
                    meta_desc: metaDesc,
                  },
                }}
              >
                <Button className="submit-btn" variant="primary">
                  Next
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AddNewBlog);
