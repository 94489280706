import React, { useRef, useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CKEditor from "ckeditor4-react-advanced";

const BlogDescription = (props) => {
  const history = useHistory();
  const [editorValue, setEditorValue] = useState("");
  const data = props.history.location.state;

  const add = () => {
    const formData = new FormData();
    formData.append("blogTitle", data.blogTitle);
    formData.append("blogDesc", editorValue);
    formData.append("blogImage", data.blogImage);
    formData.append("author", data.author);
    formData.append("readTime", data.readTime);
    formData.append("publishDate", data.publishDate);
    formData.append("relatedBlog", data.relatedBlog);
    formData.append("metaTitle", data.meta_title);
    formData.append("metaDesc", data.meta_desc);

    axios
      .post("https://api.gamesee.tv/v2/new-blog-add", formData)
      .then((res) => {
        if (res.data.Success == 1) {
          console.log(res);
          toast.success("Blog Added Successfully!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push({
              pathname: "/allBlogs",
            });
          }, 5000);
        }
      });
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Blog Description</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <CKEditor
                    data={"Enter Blog Description"}
                    onChange={(evt) => setEditorValue(evt.editor.getData())}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => add()}
                className="submit-btn"
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(BlogDescription);
