import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from 'react-router-dom';
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import MatTable from "../../reusable/MatTable/MatTable";
import { DataGrid } from '@mui/x-data-grid';
import { Container, Card, Row, Col, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditGamesDesc(props) {
    const history = useHistory();
    console.log("props>>>", props);
    const location = useLocation();
    const rowData = props?.location.state || {};
    const [gameName, setGameName] = useState(rowData ? rowData.game_name : '');
    const [gameDescription, setGameDescription] = useState(rowData ? rowData.game_description : '');
    const [gameInstruction, setGameInstruction] = useState(rowData ? rowData.game_instruction : '');

    const handleSubmit = async () => {
        const body = {
            game_id: rowData?.game_id,
            game_description: gameDescription,
            game_instruction: gameInstruction,
        }
        const response = await instance.post(RequsetData?.updateGamesDescriptions, body);
        if (response?.data.Success == 1) {
            toast.success("Description updated successfully");
            setTimeout(() => {
                history.push("/all-games-desc");
            }, 2000)
        }
    }
    return (
        <div className="content">
            <Container>
                <ToastContainer />
                <Card>
                    <Card.Header className="heading">Edit Description</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                {rowData ? (
                                    <Form>
                                        <Form.Group controlId="formGameName">
                                            <Form.Label>Game Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={gameName}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formGameDescription">
                                            <Form.Label>Game Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={gameDescription}
                                                onChange={(e) => setGameDescription(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formGameInstruction">
                                            <Form.Label>Game Instruction</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={gameInstruction}
                                                onChange={(e) => setGameInstruction(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                ) : (
                                    <p>No data to edit</p>
                                )}


                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="footer">
                        <Button onClick={handleSubmit} className="submit-btn" variant="primary">
                            save
                        </Button>
                    </Card.Footer>
                </Card>
            </Container>
        </div>
    )
}

export default EditGamesDesc