import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Sidebar from "../../../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Edituser.css";
import axios from "axios";

const Edituser = (props) => {
  const history = useHistory();
  const [newNameValue, setNewNameValue] = useState(
    props.location.state.username
  );
  const [newEmailValue, setNewEmailValue] = useState(
    props.location.state.email
  );
  // const [newPasswordValue, setNewPasswordValue] = useState(props.location.state.password);
  const [show, setShow] = useState(false);

  const editAction = () => {
    axios
      .post("https://api.gamesee.tv/v2/panel-user-edit", {
        user_name: newNameValue,
        user_email: newEmailValue,
        user_id: props.location.state.id,
      })
      .then((res) => {
        if (res.data.Success === 1) {
          history.push("/usersdata");
          toast.success("User Deatils Editted successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warn("Could Not Update User Details", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reset = () => {
    history.push("/usersdata");
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          {show && (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            </Alert>
          )}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add User</Card.Header>
            <Card.Body>
              <Row>
                <Form id="myForm">
                  <Col className="main">
                    <Form.Group className="mb-3">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        value={newNameValue}
                        onChange={(e) => setNewNameValue(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={newEmailValue}
                        onChange={(e) => setNewEmailValue(e.target.value)}
                      />
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={newPasswordValue} placeholder="Password" onChange={e=> setNewPasswordValue(e.target.value)} readOnly/>
                        </Form.Group>    */}
                  </Col>
                </Form>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Button
                className="submit-btn"
                onClick={() => editAction()}
                variant="primary"
              >
                Submit
              </Button>
              <Button
                className="cancel-btn"
                onClick={() => reset()}
                variant="danger"
              >
                Cancel
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Edituser);
