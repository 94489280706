import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from "../../Spinner/Spinner";
import { Link, withRouter } from "react-router-dom";
import Card from "react-bootstrap/Card";

const GameCategories = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setLoader(true);
    axios.post("https://api.gamesee.tv/v2/panel-games-list").then((res) => {
      if (res.data.Success == 1) {
        let responce = res.data.data;
        console.log(responce);
        setData(responce.selectAllGames);
        setLoader(false);
      }
    });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Row>
            <Col>
              <Card className="card">
                <Card.Header className="heading">Games Categories</Card.Header>
                <Card.Body className="body">
                  {loader == true ? (
                    <Loader />
                  ) : (
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      title={"All Users"}
                      columns={[
                        {
                          title: "#",
                          field: "tableData.id",
                          render: (rowData) => rowData.tableData.id + 1,
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "ID",
                          field: "id",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          width: "2%",
                        },
                        {
                          title: "Game Name",
                          field: "name",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                        },
                        {
                          title: "Icon",
                          field: "icon",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          render: (rowData) => (
                            <img src={rowData.icon} height={50} width={50} />
                          ),
                        },
                        {
                          title: "Status",
                          field: "status",
                          lookup: { 1: "Active", 2: "Deactive" },
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                        },
                        {
                          title: "Lower URL",
                          field: "lowerUrl",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "iTunes Id",
                          field: "itunesId",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                        },
                        {
                          title: "Bundle",
                          field: "bundle",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Edit",
                          field: "Edit",
                          render: (rowData) => (
                            <Link
                              to={{ pathname: "/editGames", state: rowData }}
                            >
                              <Button
                                className="table-button"
                                varient="primary"
                              >
                                Edit
                              </Button>
                            </Link>
                          ),
                        },
                      ]}
                      options={{
                        showTitle: false,
                        paginationPosition: "top",
                        exportButton: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [
                          10,
                          20,
                          30,
                          40,
                          70,
                          { value: data.length, label: "All" },
                        ],
                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                        tableLayout: "auto",
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(GameCategories);
