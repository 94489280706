import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./newuser.css";
import axios from "axios";

const Newuser = () => {
  const [newNameValue, setNewNameValue] = useState("");
  const [newEmailValue, setNewEmailValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [selectValue, setSelectValue] = useState("1");
  const [show, setShow] = useState(false);

  const history = useHistory();

  const addNewUser = () => {
    if (
      newNameValue.length &&
      newEmailValue.length &&
      newPasswordValue.length != 0
    ) {
      axios
        .post("https://api.gamesee.tv/v2/panel-register", {
          userName: newNameValue,
          password: newPasswordValue,
          email: newEmailValue,
          role: selectValue,
        })
        .then((res) => {
          if (res.data.Success == 1) {
            history.push("/usersdata");
            toast.success("New user added successfully!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setShow(true);
          }
        });
    } else {
      toast.warning("All Fields are mandatory!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Container>
          <Card>
            <Card.Header className="heading">Add User</Card.Header>
            <Card.Body>
              <Row>
                <Col className="main">
                  <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      onChange={(e) => setNewNameValue(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setNewEmailValue(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setNewPasswordValue(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select</Form.Label>
                    <Form.Select
                      value={selectValue}
                      onChange={(e) => setSelectValue(e.target.value)}
                    >
                      <option value="1">Admin</option>
                      <option value="2">Agent</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Button
                className="submit-btn"
                onClick={() => addNewUser()}
                variant="primary"
              >
                Submit
              </Button>
              <Button className="cancel-btn" variant="danger">
                Cancel
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Newuser);
