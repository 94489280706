import React, { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Cards from "../../Cards/Cards";
import CountUp from "react-countup";
import axios from "axios";
import Loader from "../../Spinner/Spinner";
import "./uploadedvideosreport.css";

const UploadedVideosReport = () => {
  const [data, setData] = useState([]);
  const [uploadTotal, setUploadTotal] = useState("");
  const [uploadActive, setUploadActive] = useState("");
  const [uploadDeactive, setUploadDeactive] = useState("");
  const [uploadPending, setUploadPending] = useState("");
  const [loader, setLoader] = useState(false);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/day-wise-upload-videos", {
        user_id: 1,
      })
      .then((res) => {
        if ((res.data.Success = 1)) {
          setData(res.data.data.dayWiseUploadRecords);
          setLoader(false);
        }
      });
    setUploadTotal(localStorage.getItem("upload-total"));
    setUploadActive(localStorage.getItem("upload-active"));
    setUploadDeactive(localStorage.getItem("upload-deactive"));
    setUploadPending(localStorage.getItem("upload-pending"));
  }, []);
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <h3 className="row-heading">Uploaded Videos</h3>
          <Row className="row">
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#009efa"
                title={<CountUp duration={3} end={uploadTotal} />}
                text="Total Videos"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#00c4ce"
                title={<CountUp duration={3} end={uploadActive} />}
                text="Active Videos"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#f86c6b"
                title={<CountUp duration={3} end={uploadDeactive} />}
                text="Deactive Videos"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#ffa500"
                title={<CountUp duration={3} end={uploadPending} />}
                text="Pending Videos"
              />
            </Col>
          </Row>
          <div className="uploadedVideoTable">
            <Card>
              <Card.Header className="heading">
                Uploaded Videos Table
              </Card.Header>
              <Card.Body>
                {loader == true ? (
                  <Loader />
                ) : (
                  <MaterialTable
                    data={data}
                    icons={tableIcons}
                    title={"Uploaded Videos Report"}
                    columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                      {
                        title: "#",
                        field: "tableData.id",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Date",
                        field: "dd",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Total",
                        field: "total",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Active",
                        field: "active",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Deactive",
                        field: "deactive",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Pending",
                        field: "pending",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                    ]}
                    options={{
                      paginationPosition: "top",
                      showTitle: false,
                      search: false,
                      exportButton: true,
                      paging: true,
                      // pageSize: rowsPerPage,
                      pageSizeOptions: [
                        5,
                        20,
                        40,
                        70,
                        { value: data.length, label: "All" },
                      ],
                      //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};
export default withRouter(UploadedVideosReport);
