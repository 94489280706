import React from 'react';
import './SidebarRow.css';

const SidebarRow = ({selected, Icon, title,status}) => {
  return( 
    <>
    <div className={`sidebarRow ${selected && 'selected'}`}>
      {status?
      <Icon className='sidebarRow__icon' />:null}
      <h2 className="sidebarRow__title">{title}</h2>
    </div>
    </>
  )};

export default SidebarRow;
