import React, { useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Toast from "../../reusable/Toast/Toast";
import moment from "moment-timezone";

function SingleUserCoinReport(props) {
    const previousData = props.location.state;
    console.log("props>>>", previousData);
    const [res, setRes] = useState([]);
    const fetchData = async () => {
        const response = await instance.post(
            RequsetData.singleUserCoinReport, {
            "type": 2,
            "user_id": previousData.user_id,
            "device_id": previousData.device_id,
            "date": moment(previousData.transaction_day, "DD/MM/YYYY").format("YYYY-MM-DD"),
        }
        );
        if (response?.status === 200) {
            const data = response?.data?.data?.map(d=>({...d,added_dt:moment(d.added_dt).format("DD/MM/YYYY")}))
            setRes(data);
            return data;
        }
    };

    const { data, status } = useQuery(["coins"], fetchData);

    console.log("data<<>>", data);
    return (
        <div className="content">
            <Toast />
            {status === "loading" ? (
                <p style={{textAlign:'center'}}>Loading...</p>
            ) : (
                <MatTable
                    heading={"Single user Coins Report"}
                    data={res}
                    dropdown={false}
                    cols={[
                        {
                            title: "#",
                            field: "tableData.id",
                            render: (rowData) => rowData.tableData.id + 1,
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "User Id",
                            field: "user_id",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Device Id",
                            field: "device_id",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Coins",
                            field: "coins",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Coins Type",
                            field: "coin_type",
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                        {
                            title: "Date",
                            field: "added_dt",
                            render: (rowData) => rowData.added_dt,
                            cellStyle: {
                                borderRight: "1px solid #e0e0e0",
                                borderLeft: "1px solid #e0e0e0",
                                wordBreak: "break-all",
                            },
                        },
                    ]}
                />
            )}
        </div>
    )
}

export default SingleUserCoinReport