import React, { useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Toast from "../../reusable/Toast/Toast";
import { toast } from "react-toastify";
import moment from "moment";

function DayWiseGamesRecords() {
  const [res, setRes] = useState([]);
  const fetchData = async () => {
    const response = await instance.get(
      RequsetData.fetchDaywiseMiniGamesReport
    );
    if (response?.status === 200) {
      setRes(response?.data?.data);
      return response;
    }
  };

  const { data, status, error } = useQuery(["GamesReport"], fetchData);

  return (
    <div className="content">
      <Toast />
      {status === "loading" ? (
        "Loading......."
      ) : (
        <MatTable
          heading={"Mini-Games Dialy Report"}
          data={res}
          cols={[
            {
              title: "#",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
              },
            },
            {
              title: "Unique User",
              field: "uniuser",
              cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
              },
            },
            {
              title: "Total User",
              field: "total",
              cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
              },
            },
            {
              title: "Date",
              field: "added_date",
              render: (rowData) =>
                moment(rowData.added_date).format("DD/MM/YYYY"),
              cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
              },
            },
          ]}
        />
      )}
    </div>
  );
}

export default DayWiseGamesRecords;
