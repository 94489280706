import React,{useState,forwardRef} from 'react';
import Sidebar from '../../Sidebar/MainSidebar/Sidebar';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const Previous = () => {
    const [data, setData] = useState([{id: '1223', surname: 'Baran', birthYear: 1987, birthCity: 63},
    {name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34},]);
    const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  return (
        <>
            <Sidebar />
            <div className='content'>
                <Container>
                <Card>
                    <Card.Header className='heading'>Previous Events</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <MaterialTable
                                    data={data}
                                    icons={tableIcons}
                                    title={'All Users'}
                                    columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                                    {title:'ID', field:'id'},
                                    {title:'Event Name',field:'event_name'},
                                    {title:'Event Date Time',field:'Event_Date_Time'},
                                    {title:'Room ID',field:'Room_Id'},
                                    {title:'Password',field:'password'},
                                    {title:'Price',field:'price'},
                                    {title:'Status',field:'status'},
                                    {title:'Participants',field:'Participants'},
                                    ]}
          
                                    options={{
                                        exportButton:true,
                                        paging:true,
                                        // pageSize: rowsPerPage,
                                        pageSizeOptions: [5, 10, 15 , { value: data.length, label: 'All' }],
                                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                                    }}   
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            </div>
        </>
  )
};

export default Previous;
