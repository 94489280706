import React, { forwardRef, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Monitization() {
  const [data, setData] = useState([]);
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [colorChange, setColorChange] = useState("");

  const actionPerformed = async (idVal, action) => {
    const body = {
      id: idVal,
      status: action,
    };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/gamesee-monetrization-apply-user-action-taken",
        body
      );
      if (action == 1) {
        if (responce.data.Success == 1) {
          setAccepted(!accepted);
          console.log(responce);
          toast.success("User Accepted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("error.....");
        }
      } else {
        if (responce.data.Success == 1) {
          // console.log("responce2......", responce);
          setRejected(!rejected);
          toast.error("User Rejected", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("error......");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, [accepted, rejected]);

  const getData = async () => {
    try {
      const responce = await axios.get(
        "https://api.gamesee.tv/v2/gamesee-monetrization-apply-user"
      );
      if (responce.data.Success == 1) {
        setData(responce.data.data);
        console.log(responce.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          ;
          <Row>
            <Col>
              <Card className="card">
                <Card.Header className="heading">
                  Monetization Table
                </Card.Header>
                <Card.Body className="body">
                  <MaterialTable
                    data={data}
                    icons={tableIcons}
                    columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                      {
                        title: "#",
                        field: "tableData.id",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Full Name",
                        field: "full_name",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      // {
                      //   title: "User Id",
                      //   field: "user_id",
                      //   cellStyle: {
                      //     borderRight: "1px solid #e0e0e0",
                      //     wordBreak: "break-all",
                      //   },
                      // },
                      {
                        title: "Address",
                        field: "address",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Phone Number",
                        field: "phone_number",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Identity Proof",
                        render: (rowData) => (
                          <a href={rowData.identity_prrof}>
                            {rowData.identity_prrof}
                          </a>
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Identity Proof Back",
                        render: (rowData) => (
                          <div onClick={setColorChange(rowData.id)}>
                            <a
                              style={{ color: "black" }}
                              href={rowData.identity_proof_back}
                            >
                              {rowData.identity_proof_back}
                            </a>
                          </div>
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Added Date",
                        render: (rowData) => (
                          <Moment
                            date={rowData.added_date}
                            format="YYYY/MM/DD hh:mm A"
                          />
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                        },
                      },
                      {
                        title: "Accept",
                        render: (rowData) => (
                          <button
                            style={{
                              width: 70,
                              height: 50,
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                            onClick={() => actionPerformed(rowData.id, 1)}
                            className="btn btn-success btn-sm"
                          >
                            Accept
                          </button>
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Reject",
                        render: (rowData) => (
                          <button
                            style={{
                              width: 70,
                              height: 50,
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                            onClick={() => actionPerformed(rowData.id, 2)}
                            className="btn btn-danger btn-sm"
                          >
                            Reject
                          </button>
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                    ]}
                    options={{
                      showTitle: false,
                      exportButton: true,
                      paging: true,
                      pageSizeOptions: [
                        5, 20, 40, 70,
                        // { value: data.length, label: "All" },
                      ],
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Monitization;
