import React from "react";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import { Link } from "react-router-dom";
import Loader from "../Spinner/Spinner";
import Toast from "../../reusable/Toast/Toast";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";

function VideosMeta() {
  const [isSearched, setIsSearched] = React.useState(false);
  const [searchData, setSearchData] = React.useState([]);

  const fetchData = async () => {
    const response = await instance.post(RequsetData.fetchvideosMetas);
    if (response?.status === 200) {
      return response;
    }
  };

  const searchFnc = async (message) => {
    if (message.length > 0) {
      setIsSearched(false);
      const body = {
        slug: message,
        type: 1,
      };
      const searchResponce = await instance.post(RequsetData.searchData, body);
      if (searchResponce?.status === 200) {
        setIsSearched(true);
        setSearchData(searchResponce?.data?.data);
      }
    }
  };

  const { data, status, error } = useQuery(["GamesReport"], fetchData);

  return (
    <div className="content">
      <Toast />
      {status === "loading" ? (
        <Loader />
      ) : (
        <>
          <MatTable
            infoText={"Search using streamkey........"}
            searchFnc={searchFnc}
            heading={"Vidoes Metas"}
            search={false}
            data={isSearched ? searchData : data?.data?.data?.videos_meta}
            cols={[
              {
                title: "#",
                field: "tableData.id",
                render: (rowData) => rowData.tableData.id + 1,
                cellStyle: {
                  borderRight: "1px solid #e0e0e0",
                  borderLeft: "1px solid #e0e0e0",
                  wordBreak: "break-all",
                },
              },
              {
                title: "Video Title",
                field: "video_title",
                cellStyle: {
                  borderRight: "1px solid #e0e0e0",
                  borderLeft: "1px solid #e0e0e0",
                  wordBreak: "break-all",
                },
              },
              {
                title: "Streamkey",
                field: "video_code",
                cellStyle: {
                  borderRight: "1px solid #e0e0e0",
                  borderLeft: "1px solid #e0e0e0",
                  wordBreak: "break-all",
                },
              },
              {
                title: "Meta Title",
                field: "meta_title",
                cellStyle: {
                  borderRight: "1px solid #e0e0e0",
                  borderLeft: "1px solid #e0e0e0",
                  wordBreak: "break-all",
                },
              },
              {
                title: "Meta Desc",
                field: "meta_desc",
                cellStyle: {
                  borderRight: "1px solid #e0e0e0",
                  borderLeft: "1px solid #e0e0e0",
                  wordBreak: "break-all",
                },
              },
              {
                title: "Edit",
                render: (rowData) => (
                  <Link to={{ pathname: "/edit-videos-meta", state: rowData }}>
                    <Button
                      className="table-button"
                      variant="primary"
                      size="md"
                    >
                      Edit
                    </Button>
                  </Link>
                ),
              },
            ]}
          />
        </>
      )}
    </div>
  );
}

export default VideosMeta;
