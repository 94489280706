import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const EditGames = (props) => {
  const [gameId, setGameId] = useState(props.location.state.id);
  const [gameType, setGameType] = useState(props.location.state.game_type);
  const [gameName, setGameName] = useState(props.location.state.name);
  const [iTunesId, setiTunesId] = useState(props.location.state.itunesId);
  const [bundle, setBundle] = useState(props.location.state.bundle);
  const [packageName, setPackageName] = useState(
    props.location.state.androidPackageName
  );
  const [gameImage, setGameImage] = useState(props.location.state.icon);
  const [gameCoverImage, setGameCoverImage] = useState(
    props.location.state.cover_image
  );
  const [metaTitle, setMetaTitle] = useState(props.location.state.metatitle);
  const [metaDescription, setMetaDescription] = useState(
    props.location.state.metadescription
  );
  const [gameStatus, setGameStatus] = useState("1");
  const [orientation, setOrientation] = useState(
    props.location.state.game_orentitation
  );

  console.log(props.location.state);
  localStorage.setItem("game-image", gameImage);

  const history = useHistory();

  const cancel = () => {
    history.push("/fetchGames");
  };

  const submit = () => {
    const formData = new FormData();
    formData.append("gameType", gameType);
    formData.append("gameName", gameName);
    formData.append("gameImage", gameImage);
    formData.append("gameCoverImage", gameCoverImage);
    formData.append("itunesId", parseInt(iTunesId));
    formData.append("bundle", bundle);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDesc", metaDescription);
    formData.append("androidPackageName", packageName);
    formData.append("gameOrentitation", orientation);
    formData.append("gameId", parseInt(gameId));
    axios
      .post("https://api.gamesee.tv/v2/panel-edit-new-game", formData)
      .then((res) => {
        console.log(res);
        if (res.data.Success == 1) {
          toast.success("Game Editted successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push("/fetchGames");
        }
      });
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add New Games</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Type</Form.Label>
                    <Form.Select
                      value={gameType}
                      onChange={(e) => setGameType(e.target.value)}
                    >
                      {gameType == 1 ? (
                        <>
                          <option value="1">Mobile</option>
                          <option value="2">Desktop</option>
                        </>
                      ) : (
                        <>
                          <option value="2">Desktop</option>
                          <option value="1">Mobile</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>orientation</Form.Label>
                    <Form.Select
                      value={orientation}
                      onChange={(e) => setOrientation(e.target.value)}
                    >
                      {orientation == 0 ? (
                        <>
                          <option value="0">None</option>
                          <option value="1">Potrait</option>
                          <option value="2">Landsacpe</option>
                        </>
                      ) : orientation == 1 ? (
                        <>
                          <option value="1">Potrait</option>
                          <option value="2">Landsacpe</option>
                          <option value="0">None</option>
                        </>
                      ) : (
                        <>
                          <option value="2">Landsacpe</option>
                          <option value="1">Potrait</option>
                          <option value="0">None</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={gameName}
                      placeholder="Game Name"
                      onChange={(e) => setGameName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>iTunes ID </Form.Label>
                    <Form.Control
                      type="number"
                      value={iTunesId}
                      placeholder="iTunes ID"
                      onChange={(e) => setiTunesId(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Bundle</Form.Label>
                    <Form.Control
                      type="text"
                      value={bundle}
                      placeholder="Bundle"
                      onChange={(e) => setBundle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Android Package Name *</Form.Label>
                    <Form.Control
                      value={packageName}
                      type="text"
                      placeholder="Android Package Name"
                      onChange={(e) => setPackageName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Image</Form.Label>
                    <Row>
                      <Col xl={2} md={5}>
                        <img src={gameImage} width={60} height={60} />
                      </Col>
                      <Col xl={10} md={7}>
                        <Form.Control
                          type="file"
                          onChange={(e) => setGameImage(e.target.files[0])}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Cover Image</Form.Label>
                    <Row>
                      <Col xl={2} md={5}>
                        <img src={gameCoverImage} width={60} height={60} />
                      </Col>
                      <Col xl={10} md={7}>
                        <Form.Control
                          type="file"
                          onChange={(e) => setGameCoverImage(e.target.files[0])}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={metaTitle}
                      placeholder="Meta Title"
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      type="text"
                      value={metaDescription}
                      placeholder="Meta Description"
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Staus</Form.Label>
                    <Form.Select
                      value={gameStatus}
                      onChange={(e) => setGameStatus(e.target.value)}
                    >
                      {gameStatus == 1 ? (
                        <>
                          <option value="1">Active</option>
                          <option value="2">Deactive</option>
                        </>
                      ) : (
                        <>
                          <option value="2">Deactive</option>
                          <option value="1">Active</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Button
                className="submit-btn"
                onClick={() => submit()}
                variant="primary"
              >
                Submit
              </Button>
              <Button
                onClick={() => cancel()}
                className="cancel-btn"
                variant="danger"
              >
                Cancel
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(EditGames);
