import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AddNewGiveaway = () => {
  const [task, setTask] = useState("1");
  const [giveawayImage, setGiveawayImage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shortDesc, setShortDesc] = useState("");

  const history = useHistory();

  const formData = new FormData();
  formData.append("giveawayType", task);
  formData.append("giveawaystartDate", startDate);
  formData.append("giveawayendtDate", endDate);
  formData.append("giveawaysdesc", shortDesc);
  formData.append("giveawayImage", giveawayImage);

  const add = () => {
    axios
      .post("https://api.gamesee.tv/v2/new-add-giveaway", formData)
      .then((res) => {
        if (res.data.Success === 1) {
          toast.success("Giveaway Added successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // console.log(res);
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add New Giveaway</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      value={task}
                      onChange={(e) => setTask(e.target.value)}
                    >
                      <option value="1">Video Of the Month</option>
                      <option value="2">Viewer Of the Month</option>
                      <option value="3">Contributer Of the Month</option>
                      <option value="4">Shots of the Month</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Giveaway Image</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setGiveawayImage(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Short Description</Form.Label>
                  </Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Giveaway Description"
                    onChange={(e) => setShortDesc(e.target.value)}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => add()}
                className="table-button"
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AddNewGiveaway;
