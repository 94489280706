import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Card, Form, Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import axios from "axios";

const EditFaq = (props) => {
  const [faqCategory, setFaqCategory] = useState(props.location.state.category);
  const [faqTitle, setFaqTitle] = useState(props.location.state.title);
  const [faqVideoLink, setFaqVideoLink] = useState(props.location.state.video);
  const [faqLanguage, setFaqLanguage] = useState(props.location.state.lang);
  const [checkedItems, setCheckedItems] = useState([]);
  const [RelatedFaq, setRelatedFaq] = useState([]);
  const [shortDesc, setShortDesc] = useState(props.location.state.short_desc);

  console.log(props.location.state);

  useEffect(() => {
    axios
      .post("https://api.gamesee.tv/v2/related-faq", {
        id: props.location.state.id,
      })
      .then((res) => {
        setRelatedFaq(res.data.data.relatedList);
      });
  }, []);

  // const add = () => {
  //   console.log(checkedItems);
  // };

  const handleCheckbox = (event) => {
    var updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Edit FAQs</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      value={faqCategory}
                      onChange={(e) => setFaqCategory(e.target.value)}
                    >
                      {faqCategory == "General" ? (
                        <>
                          <option value="G">General</option>
                          <option value="W">Web</option>
                          <option value="A">Android</option>
                          <option value="I">iOS</option>
                        </>
                      ) : faqCategory == "Web" ? (
                        <>
                          <option value="W">Web</option>
                          <option value="A">Android</option>
                          <option value="I">iOS</option>
                          <option value="G">General</option>
                        </>
                      ) : faqCategory == "Android" ? (
                        <>
                          <option value="A">Android</option>
                          <option value="I">iOS</option>
                          <option value="G">General</option>
                          <option value="W">Web</option>
                        </>
                      ) : (
                        <>
                          <option value="I">iOS</option>
                          <option value="A">Android</option>
                          <option value="G">General</option>
                          <option value="W">Web</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={faqTitle}
                      placeholder="FAQ Title"
                      onChange={(e) => setFaqTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>FAQ Video</Form.Label>
                    <Form.Control
                      type="text"
                      value={faqVideoLink}
                      placeholder="FAQ Video Link"
                      onChange={(e) => setFaqVideoLink(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Language</Form.Label>
                    <Form.Select
                      value={faqLanguage}
                      onChange={(e) => setFaqLanguage(e.target.value)}
                    >
                      <option value="en">English US</option>
                      <option value="Malay">Malay</option>
                      <option value="Portuguese">Portuguese</option>
                      <option value="Indonesian">Indonesian</option>
                      <option value="Khmer">Khmer</option>
                      <option value="Spanish">Spanish</option>
                      <option value="Bangla">Bangla</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Related FAQs</Form.Label>
                    <div className="related">
                      {RelatedFaq.map((item, i) => (
                        <Form.Check
                          style={{ color: "black" }}
                          key={i}
                          type="checkbox"
                          value={item.id}
                          onChange={handleCheckbox}
                          label={item.title}
                        />
                      ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={shortDesc}
                      placeholder="FAQ Title"
                      onChange={(e) => setShortDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Link
                to={{
                  pathname: "/editfAq_Desc",
                  state: {
                    category: faqCategory,
                    title: faqTitle,
                    faqVideo: faqVideoLink,
                    language: faqLanguage,
                    relatedFaq: checkedItems,
                    shortDescription: shortDesc,
                  },
                }}
              >
                <Button
                  // onClick={() => add()}
                  className="submit-btn"
                  variant="primary"
                >
                  Next
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(EditFaq);
