import React,{useState, forwardRef} from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Sidebar from '../../Sidebar/MainSidebar/Sidebar';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './topstreamervideoreport.css';
import Loader from '../../Spinner/Spinner';

const TopStreamerVideoReport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [gameName, setGameName] = useState('');
    const [data, setData] = useState([]);
    const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  return(
    <>
        <Sidebar />
        <div className='content'>
            <Container>
             <Card>
                <Card.Header className='heading'>Livestream Videos Table</Card.Header>
                <Card.Body>
                    <Row>
                    <Col className='cols' md={4} sm={12} xs={12}>
                        <Form.Group>
                            <Form.Select>
                                <option value="1">Active Videos</option>
                                <option value="2">Deactive Videos</option>
                                <option value="3">Pending Videos</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Game Name" onChange={e=> setGameName(e.target.value)} />
                        </Form.Group>
                    </Col>    
                    <Col lg={4} md={4} sm={12} xs={12}>
                    <Form.Group>
                        <InputGroup size="md" className="mb-3">
                            {/* <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" /> */}
                            <Form.Control aria-describedby="basic-addon1" type="date" placeholder="Enter Email..." onChange={e=> setStartDate(e.target.value)} />
                            <InputGroup.Text id="inputGroup-sizing-sm">To</InputGroup.Text>
                            <Form.Control aria-describedby="basic-addon1" type="date" placeholder="Enter Email..." onChange={e=> setEndDate(e.target.value)} />
                        </InputGroup>
                    </Form.Group>
                    </Col>
                    <Col>
                        <Button className='table-button' variernt='primary'>Search</Button>
                    </Col>
                </Row>
                <div className='top-videos-table'>
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      title={'All Users'}
                      columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                          {title:'ID',field:'id'},
                          {title:'User Profile',field:'User_Profile'},
                          {title:'Email',field:'Email'},
                          {title:'Title',field:'Title'},
                          {title:'Links',field:'Links'},
                          {title:'Status',field:'Status'},
                          {title:'Start Time',field:'Start_Time'},
                          {title:'End Time',field:'End_Time'},
                          {title:'Break Time',field:'Break_Time'},
                        ]}
          
                      options={{
                        exportButton:true,
                        paging:true,
                        // pageSize: rowsPerPage,
                        pageSizeOptions: [10, 20 , 30, 50, 70, { value: data.length, label: 'All' }],
                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                      }}   
                    />
                </div>
                </Card.Body>
            </Card>
            </Container>    
        </div>
    </>
  )
};

export default TopStreamerVideoReport;
