import React, { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Cards from "../../Cards/Cards";
import axios from "axios";
import Loader from "../../Spinner/Spinner";

const DeactivatedVideoReport = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  if (data.length > 0) {
    var i = 1;
    for (i = 1; i <= data.length; i++) {
      //  console.log(i);
      data["id"] = i;
    }
    console.log(data);
  }
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  useEffect(() => {
    setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/day-wise-deactive-upload-videos", {
        user_id: 1,
      })
      .then((res) => {
        if ((res.data.Success = 1)) {
          console.log(res.data.data.dayWisedeatctiveUploadRecords);
          setData(res.data.data.dayWisedeatctiveUploadRecords);
          setLoader(false);
        }
      });
  }, []);
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <h3 className="row-heading">Deactivated Videos Report</h3>
          <Row className="row">
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#20a8d8"
                title="8806"
                text="Total Videos"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#63c2de"
                title="1109 (12.59%) "
                text="Other"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#ffc107"
                title="1300 (14.76%)"
                text="Technical"
              />
            </Col>
            <Col className="cloumns" md={true}>
              <Cards
                color="white"
                background="#f86c6b"
                title="2246 (25.51%)"
                text="Irrelevant"
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="cloumns" xl={3} md={3}>
              <Cards
                color="white"
                background="#20a8d8"
                title="3592 (40.79%)"
                text="Quality"
              />
            </Col>
            <Col className="cloumns" xl={3} md={3}>
              <Cards
                color="white"
                background="#f86c6b"
                title="559 (6.35%)"
                text="SCR Video"
              />
            </Col>
          </Row>
          <div className="deactivatedVideoTable">
            <Card>
              <Card.Header className="heading">
                Deactivated Videos Table
              </Card.Header>
              <Card.Body>
                {loader == true ? (
                  <Loader />
                ) : (
                  <MaterialTable
                    data={data}
                    icons={tableIcons}
                    title={"All Users"}
                    columns={[
                      // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                      {
                        title: "Date",
                        field: "dd",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Total",
                        field: "total",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Technical Issues",
                        field: "technical",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Irrelevant Content",
                        field: "irrelevant",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Quality Issues",
                        field: "quality",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "SCR Video",
                        field: "SCR_video",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Other",
                        field: "no_reason",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                    ]}
                    options={{
                      search: false,
                      showTitle: false,
                      paginationPosition: "top",
                      exportButton: true,
                      paging: true,
                      // pageSize: rowsPerPage,
                      pageSizeOptions: [
                        5,
                        10,
                        15,
                        { value: data.length, label: "All" },
                      ],
                      //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(DeactivatedVideoReport);
