import React from 'react'
import {Spinner} from 'react-bootstrap'

function Loader() {
  return (
    <Spinner
        style={{display:'flex',margin:'auto'}}
        as="span"
        variant="warning"
        size="lg"
        role="status"
        aria-hidden="true"
        animation="border"/>
  )
}

export default Loader