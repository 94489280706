import React, { useState, forwardRef, useEffect } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Loader from "../../Spinner/Spinner";

const LivestreamDurationReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const submit = () => {
    setSubmitted(!submitted);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/day-wise-live-video-duration-reports", {
        user_id: 1,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (res.data.Success == 1) {
          setData(res.data.data.dayWiseCoinsRecords);
          console.log(res.data.data.dayWiseCoinsRecords);
          setLoader(false);
        }
      });
  }, [submitted]);
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">
              Livestream Duration Table
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form>
                    <Form.Group>
                      <Form.Label>Select Date</Form.Label>
                      <InputGroup size="md" className="mb-3">
                        {/* <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" /> */}
                        <Form.Control
                          aria-describedby="basic-addon1"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        <InputGroup.Text id="inputGroup-sizing-sm">
                          To
                        </InputGroup.Text>
                        <Form.Control
                          aria-describedby="basic-addon1"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="table-button"
                    variant="primary"
                    onClick={() => submit()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="top-streamer-table">
                    {loader == true ? (
                      <Loader />
                    ) : (
                      <MaterialTable
                        data={data}
                        icons={tableIcons}
                        title={"All Users"}
                        columns={[
                          // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                          { title: "S.No", field: "id" },
                          { title: "Date", field: "date" },
                          { title: "Duration (<1)", field: "less_than_one" },
                          {
                            title: "Duration (1-10 mins)",
                            field: "one_to_ten",
                          },
                          { title: "Duration (10-30)", field: "ten_to_thirty" },
                          {
                            title: "Duration (30-60)",
                            field: "thirty_to_sixty",
                          },
                          {
                            title: "Duration (Above 60)",
                            field: "sixty_above",
                          },
                          {
                            title: "Total Active",
                            field: "total_active_videos",
                          },
                        ]}
                        options={{
                          paginationPosition: "top",
                          search: false,
                          exportButton: true,
                          paging: true,
                          // pageSize: rowsPerPage,
                          pageSizeOptions: [
                            5,
                            20,
                            50,
                            70,
                            { value: data.length, label: "All" },
                          ],
                          //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default LivestreamDurationReport;
