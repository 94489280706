export const RequsetData = {
  fetchDaywiseMiniGamesReport: "/v2/dayWiseMiniGamesRecord",
  fetchstreamersMetas: "/v2/all-streamers-metas",
  editstreamersMetas: "/v2/edit-streamers-metas",
  fetchvideosMetas: "/v2/all-videos-metas",
  editvideosMetas: "/v2/edit-videos-metas",
  searchData: "/v2/search-videos-metas",
  addRemoveRecomended: "/v2/add-remove-recomended-games",
  allMiniGames: "/v2/all-mini-games-list",
  addRemoveEditorsChoice: "/v2/add-remove-editors-choice",
  zeroSearchResults: "/v2/zero-search-results",
  topSearchResults: "/v2/top-search-results",
  recomendedGames: "/v2/recomeded-games",
  hourlySearchData: "/v2/hourly-search",
  updateKeywords: "/v2/add-keywords",
  coinSytemReport: "/v2/day-wise-coin-report",
  userCoinEarnDayWise: "/v2/day-wise-user-coin-report",
  singleUserCoinReport: "/v2/single-user-coin-report",
  allGamesDescriptions: "/v2/all-games-descriptions",
  updateGamesDescriptions: "/v2/update-game-descriptions",
  allWebsitePages: "/v2/all-website-pages",
};
