import React, { useState, forwardRef } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import axios from "axios";
import Loader from "../../Spinner/Spinner";

const CoinsReport = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/day-wise-coins-reports", {
        user_id: 1,
      })
      .then((res) => {
        if (res.data.Success == 1) {
          setData(res.data.data.dayWiseCoinsRecords);
          // console.log(res);
          setLoader(false);
        }
      });
  }, []);
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Coins Report</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  {loader == true ? (
                    <Loader />
                  ) : (
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      columns={[
                        // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                        {
                          title: "S.No",
                          field: "id",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "Date",
                          field: "date",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "Total User",
                          field: "total_users",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "On Signup",
                          field: "signupcoins",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "Profile Completion",
                          field: "completeprofilecoins",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "10 mins",
                          field: "tenminscoins",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                        {
                          title: "30 mins",
                          field: "thirtyminscoins",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "60 mins",
                          field: "sixtyminscoins",
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                          },
                        },
                      ]}
                      options={{
                        showTitle: false,
                        paginationPosition: "top",
                        search: false,
                        exportButton: true,
                        paging: true,
                        // pageSize: rowsPerPage,
                        pageSizeOptions: [
                          5,
                          20,
                          50,
                          70,
                          { value: data.length, label: "All" },
                        ],
                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default CoinsReport;
