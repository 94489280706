import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link, withRouter, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Card from "react-bootstrap/Card";
import Loader from "../../Spinner/Spinner";
import "./allusers.css";

const Allusers = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userId, setuserId] = useState(null);
  const [loader, setLoader] = useState(true);
  const [removed, setRemoved] = useState(false);

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    axios
      .post("https://api.gamesee.tv/v2/panel-user-list", { user_id: 1 })
      .then((res) => {
        if (res.data.Success === 1) {
          let userList = res.data.data.selectAllUser;
          setData(userList);
          setLoader(false);
        }
      });
  }, [removed]);

  const openModal = (id) => {
    setShowModal(true);
    setuserId(id);
  };

  const deleteFunction = () => {
    // setLoader(true);
    axios
      .post("https://api.gamesee.tv/v2/panel-user-delete", {
        user_id: userId,
      })
      .then((res) => {
        if (res.data.Success === 1) {
          // setLoader(false);
          setRemoved(!removed);
          setShowModal(false);
          toast.success("User Removed successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Row>
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Remove user</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are You Sure You want to Remove this User?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="button"
                  variant="secondary"
                  size="md"
                  onClick={() => setShowModal(false)}
                >
                  No
                </Button>
                <Button
                  className="button"
                  variant="primary"
                  size="md"
                  onClick={() => deleteFunction()}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
            <Col>
              <Card className="card">
                <Card.Header className="heading">Users Table</Card.Header>
                <Card.Body className="body">
                  {loader == true ? (
                    <Loader />
                  ) : (
                    <MaterialTable
                      data={data}
                      icons={tableIcons}
                      title={"All Users"}
                      columns={[
                        // {title:"Link", field:'link',render:rowData=><Link to={{pathname:`https://${rowData.website}` }}>View{console.log(rowData)}</Link>},
                        {
                          title: "#",
                          field: "tableData.id",
                          render: (rowData) => rowData.tableData.id + 1,
                          cellStyle: {
                            borderRight: "1px solid #e0e0e0",
                            borderLeft: "1px solid #e0e0e0",
                            wordBreak: "break-all",
                          },
                        },
                        {
                          title: "Userame",
                          field: "username",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                        },
                        {
                          title: "Email",
                          field: "email",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                        },
                        {
                          title: "Status",
                          field: "admin",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          lookup: { 1: "Admin", 2: "Agent" },
                        },
                        {
                          title: "Actions",
                          field: "Delete",
                          cellStyle: { borderRight: "1px solid #e0e0e0" },
                          render: (rowData) => (
                            <>
                              <Button
                                onClick={() => openModal(rowData.id)}
                                className="button"
                                variant="danger"
                                size="md"
                              >
                                Delete
                              </Button>
                              <Link
                                to={{ pathname: "/editUser", state: rowData }}
                              >
                                <Button
                                  className="edit-button"
                                  variant="primary"
                                  size="md"
                                >
                                  Edit
                                </Button>
                              </Link>
                            </>
                          ),
                        },
                      ]}
                      options={{
                        paginationPosition: "top",
                        exportButton: true,
                        paging: true,
                        // pageSize: rowsPerPage,
                        pageSizeOptions: [
                          5,
                          10,
                          15,
                          { value: data.length, label: "All" },
                        ],
                        //  pageSizeOptions:[5,{ value: 1, label: '10' }]
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default withRouter(Allusers);
