import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import Toast from "../../reusable/Toast/Toast";
import { toast } from "react-toastify";
function EditvideosMeta(props) {
  const history = useHistory();
  const data = props?.location?.state;
  console.log(data);

  const [inputVals, setInputVals] = useState({
    metaTitle: data?.meta_title,
    metaDesc: data?.meta_desc,
  });

  const changeMetas = async () => {
    const body = {
      stremkey: data.video_code,
      meta_title: inputVals.metaTitle,
      meta_desc: inputVals.metaDesc,
    };
    console.log(body);
    if (
      body.stremkey.length >= 0 &&
      body.meta_title.length >= 0 &&
      body.meta_desc.length >= 0
    ) {
      const responce = await instance.post(RequsetData.editvideosMetas, body);
      if (responce.status !== 200) return;
      toast.success("Edited Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.push({
          pathname: "/videos-meta",
        });
      }, 3000);
    } else {
      toast.error("All Fields are required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div>
      <div className="content">
        <Container>
          <Toast />
          <Card>
            <Card.Header className="heading">Edit Videos Meta</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Video Title</Form.Label>
                    <Form.Control
                      defaultValue={data?.video_title}
                      type="text"
                      placeholder="video title"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Streamkey</Form.Label>
                    <Form.Control
                      defaultValue={data?.video_code}
                      type="text"
                      placeholder="Streamkey"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      name="Meta_Title"
                      onChange={(e) => (inputVals.metaTitle = e.target.value)}
                      defaultValue={data?.meta_title}
                      type="text"
                      placeholder="Meta Title"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Meta Desc</Form.Label>
                    <Form.Control
                      name="Meta_Desc"
                      onChange={(e) => (inputVals.metaDesc = e.target.value)}
                      defaultValue={data?.meta_desc}
                      type="text"
                      placeholder="Meta Desc"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={changeMetas}
                className="table-button"
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default EditvideosMeta;
