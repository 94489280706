import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import MatTable from "../../reusable/MatTable/MatTable";
import { DataGrid } from '@mui/x-data-grid';
import { Container, Card, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function AllGamesDesc() {
    const [res, setRes] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 80 });

    useEffect(() => {
        fetchData();
    }, []);

    console.log("res>>", res);

    const fetchData = async () => {
        const response = await instance.get(RequsetData.allGamesDescriptions);
        if (response?.status === 200) {
            setRes(response?.data?.data);
            return response;
        }
    };

    const handlePageSizeChange = (pageSize) => {
        setPaginationModel({ ...paginationModel, pageSize });
    };


    const columns = [
        {
            field: "game_name",
            headerName: "Game Name",
            width: 200,
            sortable: false,
        },
        {
            field: "game_description",
            headerName: "Game Description",
            width: 400,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    lineHeight: '1.5em',
                }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "game_instruction",
            headerName: "Game Instruction",
            width: 400,
            sortable: false,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    lineHeight: '1.5em',
                }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 150,
            renderCell: (params) => (
                <Link
                    to={{ pathname: "/edit-games-desc", state: params.row }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </Link>
            ),
        },
    ];

    const pageSizeOptions = [8, 16, 24, 30];

    return (
        <div className="content">
            <Container>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Header className="heading">
                                <div className="inside-header">
                                    All Games Description
                                </div>
                            </Card.Header>
                            <Card.Body className="body">
                                <DataGrid
                                    rows={res}
                                    columns={columns}
                                    checkboxSelection
                                    getRowId={(row) => row.game_id}
                                    disableColumnMenu={true}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    // pageSizeOptions={pageSizeOptions}
                                    // onPageSizeChange={handlePageSizeChange}
                                    pagination
                                    pageSize={paginationModel.pageSize}
                                    rowsPerPageOptions={[40]}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={(model) => setPaginationModel(model)}
                                    rowHeight={150}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AllGamesDesc