//Under First Content tab Uploaded Videos
import React, { forwardRef, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Moment from "react-moment";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./uploadedVideoTable.css";
import Loader from "../../Spinner/Spinner";
import axios from "axios";

const UploadedVideoTable = () => {
  const [data, setData] = useState([]);
  const [selectValue, setSelectValue] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState("");
  const [uploadVideoId, setUploadVideoId] = useState("");
  const [deactivationReason, setDeactivationReason] = useState("1");
  const [userId, setUserId] = useState(sessionStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [pendingVideoCount, setPendingVideoCount] = useState("");
  const [activeVideoCount, setActiveVideoCount] = useState("");
  const [deactiveVideoCount, setDeactiveVideoCount] = useState("");
  const [colorChange, setColorChange] = useState("");

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getVideos();
    // axios.post('https://api.gamesee.tv/v2/panel-shot-list',
    // {"user_id":1,
    // "type":selectValue,
    // "startDate":startDate,
    // "endDate":endDate})
    // .then((res)=>{
    //   if(res.data.Success == 1)
    //   {
    //     let responce = res.data.data;
    //     setData(responce.selectAllshort);
    //     setChange(selectValue);
    //   }
    // })
  }, [submitted, activated, deactivated]);

  const getVideos = async () => {
    setLoading(true);
    const body = {
      user_id: 1,
      type: selectValue,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/panel-upload-video-list",
        body
      );
      if (responce.data.Success == 1) {
        console.log(responce);
        setPendingVideoCount(responce.data.data.countPending);
        setDeactiveVideoCount(responce.data.data.countDeactive);
        setActiveVideoCount(responce.data.data.countActive);
        setData(responce.data.data.selectAllUploadedVideo);
        setChange(selectValue);
        setLoading(false);
      } else {
        console.log(responce.data.error.error_message);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const submit = () => {
    setSubmitted(!submitted);
  };

  //Deactivate Videos
  const deactivate = async () => {
    setLoading(true);
    const body = {
      VideoId: uploadVideoId,
      reasonDeactive: deactivationReason,
      actionPerformedBy: userId,
    };
    console.log("body........", body);
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/upload-video-isDeative",
        body
      );
      console.log(responce);
      if (responce.data.Success == 1) {
        console.log(responce);
        setLoading(false);
        setDeactivated(!deactivated);
        setShowModal(false);
        toast.warning("Video Deactivated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("error......");
      }
    } catch (err) {
      console.log(err);
    }
  };
  //Activate Videos
  const activate = async (id) => {
    setLoading(true);
    const body = { VideoId: id };
    console.log("body....", body);
    try {
      const responce = await axios.post(
        "https://api.gamesee.tv/v2/upload-video-isActive",
        body
      );
      if (responce.data.Success == 1) {
        // console.log(responce);
        setActivated(!activated);
        setLoading(false);
        toast.success("Video Activated successfully!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("error.....");
      }
    } catch (err) {
      console.group(err);
    }
  };
  //Open Modal
  const openModal = (id) => {
    setShowModal(true);
    setUploadVideoId(id);
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Reason For Deactivation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Select
                  value={deactivationReason}
                  onChange={(e) => setDeactivationReason(e.target.value)}
                >
                  <option value="1">Technical Issue</option>
                  <option value="2">Irrelevant Content</option>
                  <option value="3">Quality Issues</option>
                  <option value="5">SCR Video</option>
                </Form.Select>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                variant="secondary"
                size="md"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="button"
                variant="primary"
                size="md"
                onClick={() => deactivate()}
              >
                Deactivate
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card className="card">
            <Card.Header className="heading">Uploaded Videos Table</Card.Header>
            <Card.Body className="body">
              <Row className="form-row">
                <Col className="cols" md={4} sm={6} xs={12} lg={4}>
                  <Form.Group>
                    <Form.Select
                      value={selectValue}
                      onChange={(e) => setSelectValue(e.target.value)}
                    >
                      <option value="1">
                        Pending Videos ({pendingVideoCount})
                      </option>
                      <option value="3">
                        Deactivate Videos ({deactiveVideoCount})
                      </option>
                      <option value="2">
                        Active Videos ({activeVideoCount})
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="cols" md={4} sm={6} xs={12} lg={3}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" md={4} sm={6} xs={12} lg={3}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" lg={2}>
                  <Form.Group>
                    <Button className="submit-button" onClick={() => submit()}>
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="table-container">
                {loading == true ? (
                  <Loader />
                ) : (
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: "#",
                        field: "tableData.id",
                        render: (rowData) => rowData.tableData.id + 1,
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "ID",
                        field: "id",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          borderLeft: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },

                      change == 1
                        ? {
                            title: "Activate/Deactivate",
                            cellStyle: {
                              borderRight: "1px solid #e0e0e0",
                              wordBreak: "break-all",
                            },
                            render: (rowData) => (
                              <div className="action-column">
                                <button
                                  onClick={() => activate(rowData.id)}
                                  className="btn btn-success btn-sm"
                                >
                                  Activate
                                </button>
                                <button
                                  onClick={() => openModal(rowData.id)}
                                  className="btn btn-danger btn-sm"
                                >
                                  Deactivate
                                </button>
                              </div>
                            ),
                          }
                        : change == 2
                        ? {
                            title: "Deactivate",
                            cellStyle: {
                              borderRight: "1px solid #e0e0e0",
                              wordBreak: "break-all",
                            },
                            render: (rowData) => (
                              <button
                                onClick={() => openModal(rowData.id)}
                                className="btn btn-danger btn-sm"
                              >
                                Deactivate
                              </button>
                            ),
                          }
                        : {
                            title: "Activate",
                            cellStyle: {
                              borderRight: "1px solid #e0e0e0",
                              wordBreak: "break-all",
                            },
                            render: (rowData) => (
                              <button
                                onClick={() => activate(rowData.id)}
                                className="btn btn-success btn-sm"
                              >
                                Active
                              </button>
                            ),
                          },
                      {
                        title: "Video",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                        render: (rowData) =>
                          rowData.scr_user_id > 0 ? (
                            <Link
                              to={{
                                pathname: `https://d25u15mvjkult8.cloudfront.net/videos/${rowData.scr_user_id}/${rowData.video_name}`,
                              }}
                              target="_blank"
                            >
                              https://d25u15mvjkult8.cloudfront.net/videos/
                              {rowData.scr_user_id}/{rowData.video_name}
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: `https://d25u15mvjkult8.cloudfront.net/videos/${rowData.user_id}/${rowData.video_name}`,
                              }}
                              target="_blank"
                            >
                              https://d25u15mvjkult8.cloudfront.net/videos/
                              {rowData.user_id}/{rowData.video_name}
                            </Link>
                          ),
                      },
                      {
                        title: "Title",
                        field: "video_title",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Added Date",
                        render: (rowData) => (
                          <Moment
                            date={rowData.added_date}
                            format="YYYY/MM/DD hh:mm A"
                          />
                        ),
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          // wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Username",
                        field: "user_name",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Email",
                        field: "user_email",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                      {
                        title: "Game Name",
                        field: "name",
                        cellStyle: {
                          borderRight: "1px solid #e0e0e0",
                          wordBreak: "break-all",
                        },
                      },
                    ]}
                    data={data}
                    options={{
                      showTitle: false,
                      paginationPosition: "top",
                      // paginationPosition:'bottom',
                      exportButton: true,
                      paging: true,
                      pageSizeOptions: [
                        5,
                        10,
                        20,
                        40,
                        70,
                        { value: data.length, label: "All" },
                      ],
                    }}
                  />
                )}
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(UploadedVideoTable);
