import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const UploadApk = () => {
  const [uploadApk, setUploadedApk] = useState("");
  const [apkVersion, setApkVersion] = useState("");

  const add = () => {
    const formData = new FormData();
    formData.append("uploadApk", uploadApk);
    formData.append("appVersion", apkVersion);
    axios
      .post("https://api.gamesee.tv/v2/new-add-apk", formData)
      .then((res) => {
        if (res.data.Success == 1) {
          console.log(res);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log("Error", res);
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Upload APK</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Upload APK</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setUploadedApk(e.target.files[0])}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>APK Version</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="FAQ Title"
                      onChange={(e) => setApkVersion(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => add()}
                className="table-button"
                variant="primary"
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default UploadApk;
