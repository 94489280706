import React, { useEffect, useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";

function HourlySearchReport() {
    const [res, setRes] = useState([]);
    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        const response = await instance.get(
            RequsetData.hourlySearchData
        );
        if (response?.status === 200) {
            setRes(response?.data?.data);
            return response;
        }
    };
    const columns = [
        {
            title: "#",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Hour",
            field: "hour",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Search Count",
            field: "search_count",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
    ]
    return (
        <div className="content">
            <MatTable
                heading={"Mini-Games Hourly Search Results"}
                data={res}
                cols={columns}
            />
        </div>
    )
}

export default HourlySearchReport