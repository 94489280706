//Under Second Content tab User Verification Status
import React, { forwardRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const UserVerification = () => {
  const [data, setData] = useState([]);
  const [selectValue, setSelectValue] = useState("1");
  const [gameName, setGameName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // useEffect(()=>{
  //       axios.post('https://api.gamesee.tv/v2/panel-live-video-list')
  //       .then((res)=>{
  //         if(res.data.Success){
  //           let responce =  res.data.data;
  //           setData(responce.selectLivevideo);
  //           console.log(responce.selectLivevideo);
  //         }
  //       })
  //     },[]) Need API

  const submit = () => {
    console.log(
      selectValue +
        " " +
        gameName +
        " start Date " +
        startDate +
        " End Date " +
        endDate
    );
  };
  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card className="card">
            <Card.Header className="heading">User Verify Table</Card.Header>
            <Card.Body className="body">
              <Row className="form-row">
                <Col className="cols" md={3} sm={5} xs={12} lg={true}>
                  <Form.Group>
                    <Form.Select
                      value={selectValue}
                      onChange={(e) => setSelectValue(e.target.value)}
                    >
                      <option value="1">Applied For</option>
                      <option value="2">Not Verified</option>
                      <option value="3">Verified</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="cols" md={3} sm={5} xs={12} lg={true}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Game Name"
                      onChange={(e) => setGameName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" md={3} sm={5} xs={12} lg={true}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" md={3} sm={5} xs={12} lg={true}>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="cols" lg={true}>
                  <Form.Group>
                    <Button className="submit-button" onClick={() => submit()}>
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              <MaterialTable
                icons={tableIcons}
                title={"All Users"}
                columns={[
                  {
                    title: "ID",
                    field: "id",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Username",
                    field: "username",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Email",
                    field: "email",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Phone",
                    field: "phone",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Streaming Hours",
                    field: "Streaming",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Follower Count",
                    field: "Streaming",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Country",
                    field: "Country",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                  {
                    title: "Action",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                    render: (rowData) => (
                      <Button className="table-button" variant="success">
                        Verify
                      </Button>
                    ),
                  },
                  {
                    title: "Date of birth",
                    field: "dob",
                    cellStyle: { borderRight: "1px solid #e0e0e0" },
                    width: "2%",
                  },
                ]}
                data={data}
                options={{
                  exportButton: true,
                  paging: true,
                  pageSizeOptions: [
                    5,
                    10,
                    15,
                    { value: data.length, label: "All" },
                  ],
                }}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(UserVerification);
