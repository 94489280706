import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import "./addnewfaq.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Sidebar from "../../Sidebar/MainSidebar/Sidebar";

const AddNewFAQ = () => {
  const [faqCategory, setFaqCategory] = useState("G");
  const [faqTitle, setFaqTitle] = useState("");
  const [faqVideoLink, setFaqVideoLink] = useState("");
  const [faqLanguage, setFaqLanguage] = useState("en");
  const [shortDesc, setShortDesc] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [RelatedFaq, setRelatedFaq] = useState([]);

  useEffect(() => {
    axios.post("https://api.gamesee.tv/v2/related-faq").then((res) => {
      setRelatedFaq(res.data.data.relatedList);
    });
  }, []);

  const handleCheckbox = (event) => {
    var updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    setCheckedItems(updatedList);
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <Card>
            <Card.Header className="heading">Add New FAQ</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      value={faqCategory}
                      onChange={(e) => setFaqCategory(e.target.value)}
                    >
                      <option value="G">General</option>
                      <option value="W">Web</option>
                      <option value="A">Android</option>
                      <option value="I">IOS</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FAQ Title"
                      onChange={(e) => setFaqTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>FAQ Video</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FAQ Video Link"
                      onChange={(e) => setFaqVideoLink(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Language</Form.Label>
                    <Form.Select
                      value={faqLanguage}
                      onChange={(e) => setFaqLanguage(e.target.value)}
                    >
                      <option value="en">English US</option>
                      <option value="Malay">Malay</option>
                      <option value="Portuguese">Portuguese</option>
                      <option value="Indonesian">Indonesian</option>
                      <option value="Khmer">Khmer</option>
                      <option value="Spanish">Spanish</option>
                      <option value="Bangla">Bangla</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Related FAQs</Form.Label>
                    <div className="related">
                      {RelatedFaq.map((item, i) => (
                        <Form.Check
                          style={{ color: "black" }}
                          key={i}
                          type="checkbox"
                          value={item.id}
                          onChange={handleCheckbox}
                          label={item.title}
                        />
                      ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="FAQ Title"
                      onChange={(e) => setShortDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              <Link
                to={{
                  pathname: "/FAQ_Desc",
                  state: {
                    category: faqCategory,
                    title: faqTitle,
                    faqVideo: faqVideoLink,
                    language: faqLanguage,
                    relatedFaq: checkedItems,
                    shortDescription: shortDesc,
                  },
                }}
              >
                <Button className="submit-btn" variant="primary">
                  Next
                </Button>
              </Link>
              {/* <Button onClick={()=>add()} className='submit-btn' variant="primary">
                        Next
                        </Button> */}
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default withRouter(AddNewFAQ);
