import React, { useState } from "react";
import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import Loader from "../Spinner/Spinner";
import Sidebar from "../Sidebar/MainSidebar/Sidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function AddMiniGames() {
  const [gameName, setGameName] = useState("");
  const [gameSlug, setGameSlug] = useState("");
  const [gameUrl, setGameUrl] = useState("");
  const [gameIcon, setGameIcon] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [orientation, setOrientation] = useState(1);
  const [status, setStatus] = useState(1);
  const [isLive, setIsLive] = useState(1);
  const [lowerUrl, setlowerUrl] = useState("");
  const [bundle, setBundle] = useState("");
  const [deviceType, setDeviceType] = useState(1);
  const [loading, setLoading] = useState(false);

  const addMiniGames = async () => {
    const body = {
      game_name: gameName,
      game_slug: gameSlug,
      game_url: gameUrl,
      game_icon: gameIcon,
      cover_image: coverImage,
      orientation: orientation,
      status: status,
      islive: isLive,
      lowerUrl: lowerUrl,
      bundle: bundle,
      device_type: deviceType,
    };
    const responce = await axios.post(
      "https://api.gamesee.tv/v2/playing-games-post",
      body,
      {
        headers: {
          Authorization:
            "cb70755a985f963d64ab1bb0541b0980e0edf54931f00c9f7760d17477a90d94",
          "Content-Type": "application/json",
        },
      }
    );
    if (responce?.data?.Success === 1) {
      setLoading(false);
      toast.success(responce?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.warn(responce?.data?.error?.error_message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const submit = () => {
    addMiniGames();
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Card>
            <Card.Header className="heading">Add Mini Games</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Game Name</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setGameName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Game Slug</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setGameSlug(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Game Url</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setGameUrl(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Game Icon</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setGameIcon(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Cover Image</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setCoverImage(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Orientation</Form.Label>
                    <Form.Select
                      onChange={(e) => setOrientation(e.target.value)}
                    >
                      <option value={1}>Landscape</option>
                      <option value={0}>Potrait</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select onChange={(e) => setStatus(e.target.value)}>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Is Live</Form.Label>
                    <Form.Select onChange={(e) => setIsLive(e.target.value)}>
                      <option value={1}>Live</option>
                      <option value={0}>Not Live</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Lower Url</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setlowerUrl(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Bundle</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setBundle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Device Type</Form.Label>
                    <Form.Select
                      onChange={(e) => setDeviceType(e.target.value)}
                    >
                      <option value={1}>Desktop</option>
                      <option value={0}>Mobile</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="footer">
              {loading == true ? (
                <Loader />
              ) : (
                <Button
                  className="submit-btn"
                  onClick={() => submit()}
                  variant="primary"
                >
                  Submit
                </Button>
              )}
            </Card.Footer>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default AddMiniGames;
