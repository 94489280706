import React, { useState } from 'react';
import Sidebar from '../../Sidebar/MainSidebar/Sidebar';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import axios from 'axios';
import './gamereport.css'

const GameReport = () => {
    const [email,setEmail] = useState('');
    const [startDate, setStartDate]  = useState('');
    const [endDate, setEndDate]  = useState('');

    const submit = () => {
        console.log(email,startDate,endDate);
    }
    const reset = ()=>{

    }
  return(
     <>
     <Sidebar />
        <div className='content'>
            <Container>
                <Card>
                    <Card.Header className='heading'>Streamer Report</Card.Header>
                <Card.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Enter Email..." onChange={e=> setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Select Date</Form.Label>
                            <InputGroup size="md" className="mb-3">
                                {/* <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" /> */}
                                <Form.Control aria-describedby="basic-addon1" type="date" aria-describedby="inputGroup-sizing-sm" placeholder="Enter Email..." onChange={e=> setStartDate(e.target.value)} />
                                <InputGroup.Text id="inputGroup-sizing-sm">To</InputGroup.Text>
                                <Form.Control aria-describedby="basic-addon1" type="date" aria-describedby="inputGroup-sizing-sm" placeholder="Enter Email..." onChange={e=> setEndDate(e.target.value)} />
                            </InputGroup>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                </Card.Body>
                <Card.Footer>
                    <Button className='table-button' onClick={()=>submit()} varient='primary'>Submit</Button>
                    <Button className='table-button' onClick={()=>reset()} variant="danger">
                        Cancel
                    </Button>
                </Card.Footer>
                </Card>
            </Container>
        </div>
     </>
  ) 
};

export default GameReport;
