import React, { useEffect, useState } from "react";
import { instance } from "../../reusable/Axios/Axios";
import { RequsetData } from "../../reusable/RequestData";
import MatTable from "../../reusable/MatTable/MatTable";
import { useQuery } from "react-query";
import Toast from "../../reusable/Toast/Toast";
import { Button } from "antd";

function TopSearchRepport() {
    const [res, setRes] = useState([]);
    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        const response = await instance.get(
            RequsetData.topSearchResults
        );
        if (response?.status === 200) {
            setRes(response?.data?.data);
            return response;
        }
    };
    const columns = [
        {
            title: "#",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Search Text",
            field: "search_query",
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
        {
            title: "Times Searched",
            field: "total_results",
            render: (rowData) => <Button type="primary">{rowData?.total_results}</Button>,
            cellStyle: {
                borderRight: "1px solid #e0e0e0",
                borderLeft: "1px solid #e0e0e0",
                wordBreak: "break-all",
            },
        },
    ]
    return (
        <div className="content">
            <Toast />

            <MatTable
                heading={"Mini-Games Top Search Results (Last 30 Days)"}
                data={res}
                cols={columns}
            />
        </div>
    )
}

export default TopSearchRepport