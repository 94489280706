import React, { useState } from 'react'
import MatTable from '../../reusable/MatTable/MatTable'
import { useQuery } from 'react-query';
import { RequsetData } from '../../reusable/RequestData';
import { instance } from '../../reusable/Axios/Axios';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";




function AllminiGames() {
  const [gameData, setGameData] = useState(null);
  const fetchData = async () => {
    const response = await instance.post(RequsetData?.allMiniGames);
    if (response.data.Success === 1) {
      return response.data;
    }
  };

  const { data, status, error } = useQuery(["allMiniGames"], fetchData);
  const addRemoveRecomended = async (gameId) => {
    const body = {
      game_id: gameId
    };
    try {
      const response = await instance.post(RequsetData?.addRemoveRecomended, body);
      if (response.data.Success === 1) {
        const updatedData = (gameData || data?.data).map((game) => {
          if (game.game_id === gameId) {
            return { ...game, recomended: game.recomended === 1 ? 0 : 1 };
          }
          return game;
        });
        setGameData(updatedData);
      }
    }
    catch (err) {
      console.error("Error:", error);
    }
  }

  const addRemoveEditorsChoice = async (gameId) => {
    const body = {
      game_id: gameId
    };
    try {
      const response = await instance.post(RequsetData?.addRemoveEditorsChoice, body);
      if (response.data.Success === 1) {
        const updatedData = (gameData || data?.data).map((game) => {
          if (game.game_id === gameId) {
            return { ...game, editors_choice: game.editors_choice === 1 ? 0 : 1 };
          }
          return game;
        });
        setGameData(updatedData);
      }
    }
    catch (err) {
      console.error("Error:", error);
    }
  }

  const handleToggleRecommendation = (gameId) => {
    addRemoveRecomended(gameId);
  };

  const handleToggleEditorsChoice = (gameId) => {
    addRemoveEditorsChoice(gameId);
  }

  const handleInputChange = (gameId, value) => {
    const updatedData = (gameData || data?.data).map((game) => {
      if (game.game_id === gameId) {
        return { ...game, keywords: value };
      }
      return game;
    });
    setGameData(updatedData);
  };

  const handleRowUpdate = async (game_id, keywords) => {
    console.log("fnc....")
    // Perform the update action based on your requirements
    const body = {
      game_id: game_id,
      keywords: keywords,
    };
    try {
      const response = await instance.post(RequsetData?.updateKeywords, body);
      if (response.data.Success === 1) {
        const updatedData = (gameData || data?.data).map((game) => {
          if (game_id === game.game_id) {
            return { ...game, keywords: keywords };
          }
          return game;
        });
        setGameData(updatedData);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const colums = [
    {
      title: "#",
      field: "tableData.id",
      render: (rowData) => rowData.tableData.id + 1,
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Game Name",
      field: "game_name",
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Game url",
      field: "game_url",
      render: (rowData) => <a href={rowData?.game_url}>{rowData?.game_url}</a>,
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Game Icon",
      field: "game_icon",
      render: (rowData) => <img src={rowData?.game_icon} width={40}></img>,
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Keywords",
      field: "keywords",
      render: (rowData) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            style={{ height: 35, fontSize: 16 }}
            type="text"
            defaultValue={rowData.keywords}
            onChange={(e) => handleInputChange(rowData.game_id, e.target.value)}
          />
          <Button style={{ marginLeft: 5, height: 40 }} onClick={() => handleRowUpdate(rowData?.game_id, rowData?.keywords)} size='sm' variant='success'>Update</Button>
        </div>
      ),
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "In website",
      field: "device_type",
      render: (rowData) => (rowData?.device_type === 1 ? <IconButton><CheckCircleIcon /></IconButton> : <IconButton><CancelIcon /></IconButton>),
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "In mobile",
      field: "device_type",
      render: (rowData) => (rowData?.status === 1 ? <IconButton><CheckCircleIcon /></IconButton> : <IconButton><CancelIcon /></IconButton>),
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Is Recomended",
      field: "recomended",
      render: (rowData) => (rowData?.recomended === 1 ? <Button onClick={() => handleToggleRecommendation(rowData?.game_id)} size='sm' variant='success'>Yes</Button> : <Button onClick={() => handleToggleRecommendation(rowData?.game_id)} size='sm' variant='danger'>No</Button>),
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    {
      title: "Editor's Choice",
      field: "editors_choice",
      render: (rowData) => (rowData?.editors_choice === 1 ? <Button onClick={() => handleToggleEditorsChoice(rowData?.game_id)} size='sm' variant='success'>Yes</Button> : <Button onClick={() => handleToggleEditorsChoice(rowData?.game_id)} size='sm' variant='danger'>No</Button>),
      cellStyle: {
        borderRight: "1px solid #e0e0e0",
        borderLeft: "1px solid #e0e0e0",
        wordBreak: "break-all",
      },
    },
    // {
    //   title: "Actions",
    //   field: "",
    //   render: (rowData) => <Link to={{ pathname: "/edit_mini_games", state: rowData }}><Button size='sm' >Edit</Button></Link>,
    //   cellStyle: {
    //     borderRight: "1px solid #e0e0e0",
    //     borderLeft: "1px solid #e0e0e0",
    //     wordBreak: "break-all",
    //   }, 
    // },
  ]

  return (
    <div className='content'>
      <MatTable
        heading={"All Mini Games"}
        search={true}
        data={gameData || data?.data}
        cols={colums}

      />
    </div>
  )
}

export default AllminiGames